import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row
} from 'reactstrap';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import ReactTable from "react-table";
import 'react-table/react-table.css' ;
import './style.css'
import axios from 'axios' ;
import token from '../../token.js' ;
import Select from 'react-select' ;
import { get , post } from "../../services/http";

class ProcessOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      select_value: null,
      wprog_option_list: [],
      processes: []
    } ;
  };

  change_select = (selectedOption) => {
    post('/api/walkthrough/get_processes/',{wprog_number: selectedOption.WProg_Number})
    .then( res => {
      this.setState({
        select_value: selectedOption,
        processes: res.data.processes
      });
    });
  }

  edit_process = (process_name) => {}
  show_process = (process_name) => {}
  define_process = (event) => {

  }

  componentDidMount(){
    get('/api/assign/get_workprograms/')
    .then( (res) => {
      const wprog_option_list = res.data.wprog_list[0].map((element,index) => {element['value'] = index; element['label'] = element.WProg_Title; return element})
      this.setState({wprog_option_list: wprog_option_list})
    });
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h2>Define A Walkthrough Process</h2> <br></br>
              </Col>
            </Row>
            <Row>
              <Col xs="3">
                <h4>Audit Work Program:</h4>
              </Col>
              <Col lg="9">
                <Select
                  value={this.state.select_value}
                  options={this.state.wprog_option_list}
                  onChange={this.change_select}
                  onSelectResetsInput={false}
                  placeholder="Select an Audit Work Program ..."
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <strong> Defined Processes </strong>
          </CardHeader>
          <CardBody>
            <ReactTable
              ref={(r) => {this.ProcessTable = r}}
              data={this.state.processes}
              columns={[{Header:'Process Name', accessor: 'Process_Name'},
                        {Header:'Process Description', accessor: 'Process_Description'},
                        {Header:'Process Owner', accessor: 'Process_Owner'},
                        {Header:'Definition Date', accessor: 'Definition_Date'},
                        {id: 'button', accessor: 'Process_Name',
                          Cell: ({value}) => (<button onClick={() => {this.show_process(value)}}>Show</button>),
                          maxWidth:70},
                        {id: 'button', accessor: 'Process_Name',
                          Cell: ({value}) => (<button onClick={() => {this.edit_process(value)}}>Edit</button>),
                          maxWidth:70},
                        {id: 'button', accessor: 'Process_Name',
                          Cell: ({value}) => (<button onClick={() => {this.edit_process(value)}}>Delete</button>),
                          maxWidth:70}
                        ]}
              defaultPageSize={5}
              className="-striped -highlight"
              filterable={true}
              defaultFilterMethod= {(filter, row, column) => {
                    const id = filter.pivotId || filter.id
                    return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase())>-1 : true
                  }}
            />
          </CardBody>
        </Card>
          <Col lg="2">
            <Button block color="primary" type="button" onClick={this.define_process}>Define New Process </Button>
          </Col>
      </div>
    );
  }
}

export default ProcessOverview;
