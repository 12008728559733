import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Input, 
  Label, 
  Badge,
  FormText
} from 'reactstrap';
import moment from 'moment';

import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

class DateTimePicker extends Component{

    constructor(props) {
        super(props);
        this.state = {
          startDate: moment().subtract(12, 'months'), //Could be set to 1 month by default
          endDate: moment(),
        };
      }
    
      handleChange = ({ startDate, endDate }) => {
        startDate = startDate || this.state.startDate;
        endDate = endDate || this.state.endDate;
        
        
        //console.log(this.state.startDate.toArray())

        var fromArray = startDate.toArray()
        var toArray = endDate.toArray()
        this.props.setFromDate(fromArray[0], fromArray[1]+1, fromArray[2])
        this.props.setToDate(toArray[0], toArray[1]+1, toArray[2])

        this.setState({ startDate, endDate });
  
      };
    

      //Ensure that the default values for the date are set in the parent class
      componentDidMount(){
        var fromArray = this.state.startDate.toArray()
        var toArray = this.state.endDate.toArray()
        this.props.setFromDate(fromArray[0], fromArray[1]+1, fromArray[2])
        this.props.setToDate(toArray[0], toArray[1]+1, toArray[2])

        

      }

      handleChangeStart = (startDate) => {
          
        this.handleChange({ startDate })
        
      };
    
      handleChangeEnd = endDate => this.handleChange({ endDate });
    
      render() {

        if(this.props.versioning){
        return (
          <div className="row">
           
            <Col>
           
            <FormText className="help-block">Historical comparison date: From</FormText>
              <DatePicker
                selected={this.state.startDate}
                selectsStart
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.handleChangeStart}
                dateFormat="MMMM DD, YYYY"
                
              />
              
             </Col>
              <Col>
              <FormText className="help-block">To</FormText>
              <DatePicker
               disabled={true}
                selected={this.state.endDate}
                selectsEnd
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.handleChangeEnd}
                minDate={this.state.startDate}
                dateFormat="MMMM DD, YYYY"
              />
              
              </Col>
          </div>
        
        );}
        else{
          return(<div></div>)
        }
      }
    }

  export default DateTimePicker