import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
  Nav, NavItem, NavLink,
  TabContent, TabPane
} from 'reactstrap';

import classnames from 'classnames';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import './style.css'
import _ from 'lodash';
import axios from 'axios';


function TwoColLine(props) {
  return (
    <div>
      <Row>
        <Col xs={props.split}>
          <strong>{props.name}:</strong>
        </Col>
        <Col>
          {props.field !== "01 Jan 1900" ? props.field : "-"}
        </Col>
      </Row>
    </div>
  );
}


class IssueDetails extends Component {
  constructor(props) {
    // console.log("PROPS");
    // console.log(props);
    super(props);
    this.state = {
      actionplan_data: [],
      issue_id: props.row_data["Issue ID"],
      kpci_id: props.row_data["KPCI ID"],
      kpci_title: props.row_data["Related Controls"],
      kpci_description: props.row_data["KPCI Description"],
      kpci_data: [],
      columns: [{ Header: 'Issue ID', accessor: 'Issue ID' }, { Header: 'Issue Title', accessor: 'Issue Title' }],
      row_data: props.row_data,
      activeTab: 'general',
      column_info: [[], [], []],
    }
  }

  componentDidMount() {
    // console.log("STATE");
    // console.log(this.state);
    axios.post(process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/explore/get_issue_action/', { issue_id: this.state.issue_id })
      .then(res => {
        
        // preparing the kpci information
        var kpci_id = (this.state.kpci_id !== null ? this.state.kpci_id.split('|'): "");
        var kpci_title = ( this.state.kpci_title !== null ? this.state.kpci_title.split('|') : "");
        var kpci_description = ((this.state.kpci_description !== null && this.state.kpci_description !== undefined ) ? this.state.kpci_description.split('|'): "");
        var kpci_data = []
        for (var i = 0; i < kpci_id.length; i++) {
          kpci_data.push({ id: kpci_id[i], title: kpci_title[i], description: kpci_description[i] });
        }

        // preparing the overview information
        var row_columns = _.keys(this.state.row_data);
        var nbr_columns_per_card = Math.floor(row_columns.length / 3);
        var column_info = [row_columns.slice(0, nbr_columns_per_card),
        row_columns.slice(nbr_columns_per_card, 2 * nbr_columns_per_card),
        row_columns.slice(2 * nbr_columns_per_card, row_columns.length)];

        // preparing the action plan information
        // console.log(res.data);
        var columns = _.keys(res.data.actionplans[0][0]).map(x => { return ({ Header: x, accessor: x }) });

        //placeholder for no columns found
        if(columns.length==0){
          columns = ['No related actions plans were found']
        }
        this.setState({
          actionplan_data: res.data.actionplans[0],
          columns: columns,
          kpci_data: kpci_data,
          column_info: column_info,
        });
      }
      )
      .catch(function (error) {
        console.log(error);
      })
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  render() {
    console.log(this.state);
    return (
      <div>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === 'general' })}
                      onClick={() => { this.toggle('general'); }}
                    >
                      <strong>General Information</strong>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === 'kpci' })}
                      onClick={() => { this.toggle('kpci'); }}
                    >
                      <strong>Related KPCis</strong>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === 'action' })}
                      onClick={() => { this.toggle('action'); }}
                    >
                      <strong>Related Action Plans</strong>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="general">
                    <Row>
                      <Col>
                        <Card>
                          <CardBody>
                            {console.log(this.state)}
                            {this.state.column_info[0].map((x, index) => { return (<TwoColLine index={index} name={x.replace('Issue_', '')} field={this.state.row_data[x]} split="2" />) })}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card>
                          <CardBody>
                            {this.state.column_info[1].map((x, index) => { return (<TwoColLine index={index} name={x.replace('Issue_', '')} field={this.state.row_data[x]} split="5" />) })}
                          </CardBody>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <CardBody>
                            {this.state.column_info[2].map((x, index) => { return (<TwoColLine index={index} name={x.replace('Issue_', '')} field={this.state.row_data[x]} split="4" />) })}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="kpci">
                    <Row>
                      <Col>
                        <Card>
                          <CardBody>
                            <ReactTable
                              data={this.state.kpci_data}
                              columns={[{ Header: 'ID', accessor: 'id', width: 80 },
                              { Header: 'Title', accessor: 'title' },
                              { Header: 'Description', accessor: 'description' }]
                              }
                              defaultPageSize={5}
                              className="-striped -highlight"
                              filterable={true}
                              defaultFilterMethod={(filter, row, column) => {
                                const id = filter.pivotId || filter.id
                                return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1 : true
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="action">
                    <Card>
                      <CardBody>
                        <ReactTable
                          data={this.state.actionplan_data}
                          columns={this.state.columns}
                          defaultPageSize={5}
                          className="-striped -highlight"
                          filterable={true}
                          defaultFilterMethod={(filter, row, column) => {
                            const id = filter.pivotId || filter.id
                            return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1 : true
                          }}
                        />
                      </CardBody>
                    </Card>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export function IssueSubComponent(row) {
  return (
    <div id="">
      <Row>
        <Col lg="3">
          <IssueDetails key={row.original["Issue ID"]} row_data={row.original} />
        </Col>
      </Row>
    </div>
  )
}