import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Nav, NavItem, NavLink,
  TabContent, TabPane
} from 'reactstrap';

import classnames from 'classnames';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import ReactTable from "react-table";
import 'react-table/react-table.css' ;
import Select from 'react-select' ;
//import 'react-select/dist/react-select.css';
import './style.css';
import token from '../../token.js' ;
import axios from 'axios' ;
import { get , post, postAll } from "../../services/http";

var vis = require('vis') ;

var options = {
    height: '500px',
    width:  '100%',

    nodes:{
      mass: 2,
      shape: 'dot',
      scaling: {
        customScalingFunction: function (min,max,total,value) {
          return 5*value/total;
        },
        min:1,
        max:50
      },
      chosen:{
        label: function(values, id, selected, hovering) {
                  values.size = 30;
                  values.mod = 'bold' ;
                }
      },
      color:{
        highlight: {
          border: '#e92a2a',
          background: '#ff7c7c'
        },
        hover: {
          border: '#e92a2a',
          background: '#ff7c7c'
        }
      }
    },
    edges:{
      color: {
        color:'#848484',
        highlight:'#e92a2a',
        hover: '#e92a2a',
        inherit: 'both',
        opacity:1.0
      }
    },
    physics:{
      timestep: 0.35
    },
    layout:{
      randomSeed: 8888
    }
};


class Network extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1' ,
      network: {
        data: null,
        graph: null,
        number_neighbours: 1,
      },
      policy:{
        ID: null,
        Title: null,
        Summary: null,
      },
      selectedPolicy: '',
      policyOptionList: [],
      next_policies: [],
      neighbourInformation: 'The second neighbours are not shown',
      buttonText: 'Show the second neighbours',
      loading_layer_is_active: true , // NOT CURRENTLY USED AS THE LOADABLE IS DEACTIVATED
    };

    // binding the class methods as they are written in the function(...){...} syntax
    // if replaced by the func = (...) => {...} syntax this binding is no longer required
    this.toggle                     = this.toggle.bind(this) ;
    this.update_number_neighbours   = this.update_number_neighbours.bind(this);
    this.update_policy_information  = this.update_policy_information.bind(this);
    this.update_policy_nextPolicies = this.update_policy_nextPolicies.bind(this);
    this.changeSelect = this.changeSelect.bind(this);
    this.toggle2ndNeighbour = this.toggle2ndNeighbour.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  update_number_neighbours(number_neighbours){
    if (number_neighbours !== this.state.network.number_neighbours){
      this.update_graph(this.state.policy.ID, number_neighbours);
    }
  }

  update_policy_information(id){
    if (id !== this.state.policy.ID){
      post('/api/explore/get_network_nodes_information/', {id: id})
      .then((res) => {
          console.warn("the response is given here")
          console.log(res);
          var policy = res.data.policy[0];
          this.setState({policy: policy,
            selectedPolicy:{ID: id, value: id, Title: policy.Title, label: id + ": " + policy.Title} });
      })
      .catch(function (error) {
        console.log(error);
      })
      this.update_policy_nextPolicies(id);
      this.update_graph(id,this.state.network.number_neighbours);
    }
  }

  update_graph = (id,number_neighbours) => {
    console.log("TEST");
    console.log(id);
    console.log(number_neighbours);
    postAll([
      {url: '/api/explore/get_network_edges/', data: {number_neighbours: number_neighbours, id: id}},
      {url: '/api/explore/get_network_nodes/', data: {number_neighbours: number_neighbours, id: id}}
    ])
    .then((result) => {
      var edges = new vis.DataSet(result[0].data.edges[0]);
      var nodes = new vis.DataSet(result[1].data.nodes[0]);
      //colouring the center policy in green:
      var centerNode = nodes.get(id);
      console.log(centerNode);
      // if (centerNode != null){
      centerNode.color = {
        border: 'black',
        background: 'green'
      };
      nodes.update(centerNode);
      var data = {edges: edges, nodes: nodes};
      this.state.network.graph.setData(data);
      this.setState({network: {data: data, number_neighbours: number_neighbours, graph: this.state.network.graph}});
      this.setState({loading_layer_is_active: false});
    });
    this.setState({loading_layer_is_active: true});
  }

  update_policy_nextPolicies(id){
    if (id !== this.state.policy.ID){
      post('/api/explore/get_network_neighbours/', {id: id})
      .then((res) => {
        console.log("TEST 2");
        console.log(res.data)
        this.setState({next_policies: res.data.next_policies[0]});
      });
    }
  }

  componentDidMount(){
      get('/api/explore/get_network_policies')
      .then( (res) => {
        console.log(res)
        const policyOptionList = res.data.policy_list[0].map((element,index) => {element['value'] = element.ID; element['label'] = element.ID + ": " + element.Title; return element})
        this.setState({policyOptionList: policyOptionList})
      })
      .catch(function (error) {
        console.log(error);
      })
      var edges = new vis.DataSet(null);
      var nodes = new vis.DataSet(null);
      var data = {edges: edges, nodes: nodes};
      var graph = new vis.Network(this.refs.myRef, data, options);  // options is defined as a global variable outside the NetworkNeighbour class
      graph.on('selectNode', (params) => {
        this.update_policy_information(params.nodes[0]);
        this.update_policy_nextPolicies(params.nodes[0]);
      })
      this.setState({network: {data: data, number_neighbours: this.state.network.number_neighbours, graph: graph}});
  }

  asCenter = (id) => {
    this.update_policy_information(id);
  }

  goTo = (id) => {
    var graph = this.state.network.graph ;
    try{
      graph.selectNodes([id]) ;
    } catch(err) {
      console.log('Error: ', err)
      graph.unselectAll() ;
    }
  }

  openPolicy = (id) => {
    var policyNumber = id.substring(4, 10); ;
    window.open(process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/secure/get/policy/?id='+id+'&token='+token.getToken(), '_blank')
  }

  changeSelect = (selectedPolicy) => {
    if (this.state.policyOptionList.indexOf(selectedPolicy) > -1){
      this.setState({selectedPolicy: selectedPolicy});
      this.update_policy_information(selectedPolicy.ID);
      this.update_policy_nextPolicies(selectedPolicy.ID)
      this.update_graph(selectedPolicy.ID,this.state.network.number_neighbours);
      };
  }

  toggle2ndNeighbour() {
    if (this.state.network.number_neighbours === 1){
      this.update_number_neighbours(2);
      this.setState({
        buttonText: "Do not show the second neighbours",
      });
    }
    else {
      this.update_number_neighbours(1);
      this.setState({
        buttonText: "Show the second neighbours",
      });
    }
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h2>Policies - Network Analysis</h2> <br></br>
                  </Col>
                </Row>
                <Row>
                  <Col xs="2">
                  <h5>Central Policy:</h5>
                  </Col>
                  <Col lg="10">
                    <Select
                      value={this.state.selectedPolicy}
                      options={this.state.policyOptionList}
                      onChange={this.changeSelect}
                      onSelectResetsInput={true}
                      placeholder="Select a policy to start the analysis ..."
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <strong>Visualizing policies connected to a selected central policy: </strong>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs='12' lg='7'>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xs="12" lg="6">

                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {/* <Loadable
                              active={this.state.loading_layer_is_active}
                              spinner={true}
                              text='Loading ...'
                              > */}
                              <div ref="myRef"></div>
                            {/* </Loadable> */}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.activeTab === '1' })}
                              onClick={() => { this.toggle('1'); }}
                            >
                              <strong>Policy Information</strong>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.activeTab === '2' })}
                              onClick={() => { this.toggle('2'); }}
                            >
                              <strong>Connected Policies</strong>
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                          <TabPane tabId="1">
                            <Row>
                              <Col xs="12" lg="2">
                                <strong>Policy-ID:</strong>
                              </Col>
                              <Col>
                                 {this.state.policy.ID}
                                 {this.state.policy.ID && <button style={{marginLeft: "20px"}} onClick={() => {this.openPolicy(this.state.policy.ID)}}>Open</button>}
                              </Col>
                            </Row>
                            <Row> <br/> </Row>
                            <Row>
                              <Col xs="12" lg="2">
                                <strong>Title:</strong>
                              </Col>
                              <Col>
                                {this.state.policy.Title}
                              </Col>
                            </Row>
                            <Row> <br/> </Row>
                            <Row>
                              <Col xs="12" lg="2">
                                <strong>Summary:</strong>
                              </Col>
                              <Col>
                                {this.state.policy.Summary}
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="2">
                            <ReactTable
                              data={this.state.next_policies}
                              columns={[{Header:'Title', accessor: 'Title', className: 'hdr-set'},
                                        {id: 'button', accessor: 'ID',
                                          Cell: ({value}) => (<button onClick={() => {this.goTo(value)}}>Show</button>),
                                          maxWidth:60},
                                        {id: 'button', accessor: 'ID',
                                          Cell: ({value}) => (<button onClick={() => {this.asCenter(value)}}>GoTo</button>),
                                          maxWidth:60},
                                        {id: 'button', accessor: 'ID',
                                          Cell: ({value}) => (<button onClick={() => {this.openPolicy(value)}}>Open</button>),
                                          maxWidth:60}]}
                              defaultPageSize={12}
                              className="-striped -highlight"
                            />
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Network;
