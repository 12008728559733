import React from 'react';
import 'react-table/react-table.css';
import { Badge, Input } from 'reactstrap'
import TableFrame from '../TableFrame/TableFrame.js';
import { KPCISubComponent } from '../KPCISubComponent/KPCISubComponent.js';
import { authentication_check } from '../Authentication/Authentication.js';
import axios from 'axios';
import AssignExpandButton from '../ViewAssign/ColumnRendering/AssignExpandButton'
import TableFrameServerSide from '../TableFrame/TableFrameServerSide';
import RelevanceExplanation from '../AssignmentProcess/RelevanceExplanation';
import {get, post } from "../../services/http";
const query = '/api/explore/get_kpci/';

const query_remote =  '/api/explore/get_kpci_remote';
const table_date = 'kpci';
const query_post_assignments =  '/api/secure/assign/post_several_control_assignments/';
const query_fetch_selected_assignments =  '/api/assign/fetch_selected_control_assignments/';
const query_fetch_linked_workprograms =  '/api/assign/get_linked_workprograms_control/';


const title = 'KPCi Table';
const defaultPageSize = 10;


class KPCIExplorer extends React.Component {

  constructor(props) {
    super(props);
    this.childRef = React.createRef();
    this.state = {

    }
  }

  componentDidMount() {
      get('/api/assign/get_workprograms/')
      .then((res) => {
        const wprogOptionList = res.data.wprog_list[0];
        this.setState({ wprogOptionList: wprogOptionList })
      });
  }

  prependColumns = [

    {
      accessor: 'is_assigned',
      Header: 'Assigned',
      Filter: ({ filter, onChange }) => {
        return (
          <Input

            onKeyPress={event => {


              if (event.keyCode === 13 || event.which === 13) {
                onChange(event.target.value)
              }
            }}
          />
        )
      },
      Cell: props => {
        return (<AssignExpandButton
          trigger_update={this.childRef.current}
          document_code={props.original["Control Instance ID"]}
          work_program={props.original.is_assigned}
          wprogOptionList={this.state.wprogOptionList}
          query_post_assignments={query_post_assignments}
          query_fetch_selected_assignments={query_fetch_selected_assignments}
          query_fetch_linked_workprograms={query_fetch_linked_workprograms}
        />)
        //trigger_update = {this.childRef.current.triggerTableUpdate()}
      }
    },
    {
      Header: 'Relevance',
      id: 'relevance',
      Cell: (row) => (
        <RelevanceExplanation relevance_score={row.original['Relevance']} data={row} />
      ),
      maxWidth: 90
    },
    {
      accessor: 'StatusText',
      Header: 'Status',
      Filter: ({ filter, onChange }) => {
        return (
          <Input

            onKeyPress={event => {


              if (event.keyCode === 13 || event.which === 13) {
                onChange(event.target.value)
              }
            }}
          />
        )
      },
      Cell: ({ value }) => {

        if (value == 'NEW') {
          return (<center> <Badge className='badge badge-success'>NEW</Badge></center>)
        }
        else if (value == 'MODIFIED') {
          return (<center> <Badge className='badge badge-warning'>MODIFIED</Badge></center>)
        }
        else if (value == 'INACTIVE') {
          return (<center> <Badge className='badge badge-danger'>INACTIVE</Badge></center>)

        }
        else {
          return (<center> <Badge className='badge badge-secondary'>EXISTING</Badge></center>)
        }




      }
    }

  ]

  render() {
    return (
      <div>
        <TableFrameServerSide
          ref={this.childRef}
          query={query_remote}
          title={title}
          defaultPageSize={defaultPageSize}
          subtitle={"(quarterly refresh exp.)"}
          SubComponent={KPCISubComponent}
          prependColumns={this.prependColumns}
          versioning={true}
          wprogOptionList={this.state.wprogOptionList}
          tableDate = {table_date}/>

        {/*<TableFrame query={query} title={title} defaultPageSize={defaultPageSize} SubComponent={KPCISubComponent}/>*/}

      </div>)
  }
}

export default KPCIExplorer;
