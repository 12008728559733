import React, {Component} from 'react';
import 'react-table/react-table.css' ;
import TableFrame from '../TableFrame/TableFrame.js';

class IssueOverview extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedWProg: null,
        issue_due_date: null,
        issue_title: null,
        issue_statement: null,
        selectedRating: null,
        ubs_issue_owner: null,
        ubs_contact_last_discussed_with: null,
        owning_legal_entity: null,
        selectedStatus: null,
        updateFormActive: false
      };
    };

  updateIssue = value => {
    // axios.post()
    // .then( res => {

    // })
    // this.setState({
    //   // updateFormActive: true,
    //   selectedWProg: rowInfo.row.selectedWProg,
    //   issue_due_date: rowInfo.row.issue_due_date,
    //   issue_title: rowInfo.row.issue_title,
    //   issue_statement: rowInfo.row.issue_statement,
    //   selectedRating: rowInfo.row.selectedRating,
    //   ubs_issue_owner: rowInfo.row.ubs_issue_owner,
    //   ubs_contact_last_discussed_with: rowInfo.row.ubs_contact_last_discussed_with,
    //   owning_legal_entity: rowInfo.row.owning_legal_entity,
    //   selectedStatus: rowInfo.row.selectedStatus
    // });
  }

  render(){
    const query = '/api/issue/get_overview/';
    const title = 'Issue Overview';
    const defaultPageSize = 10;
    const appendColumns = [
      { accessor: 'Issue_Title',
        width: 80,
        Cell: value => <button onClick={() => {this.updateIssue(value)}}> Update </button>
      }
    ]
    return(
      <TableFrame query={query} title={title} defaultPageSize={defaultPageSize} appendColumns={appendColumns}/> 
    )
  }
}

export default IssueOverview;
