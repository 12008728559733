import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container , Row} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import network from '../Images/LandingPage/network.png' ;
import Cookies from 'js-cookie';
import {get} from "../../services/http";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: Cookies.get('error'),
      token: Cookies.get('jwt'),
    };
    if(this.state.token) {
      get('/api/secure/check_token')
      .then(res => {
        if (res.data.success) {
          this.props.history.push('/home');
        }
      });
    }
  }
  componentDidMount() {
    if(this.state.error) {
      toast.error('Unauthorized user, please request access if you are UBS Independent', {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: this.toggleToast
      });
    }

  }

  change_mail = (event) =>     { this.setState( { email: event.target.value} ) };
  change_password = (event) => { this.setState( { password: event.target.value} ) };

  submit_form = (ev) => {
    ev.preventDefault();
    window.location = `${process.env.REACT_APP_EXPRESS_URL}/api/login`;
  };
  toggleToast = () => {
    Cookies.remove('error');
  };

  submit_request = (ev) => {
    ev.preventDefault();
    window.location = 'mailto:EY_DAA_Admin.CH.GID@ey.net?subject=DAA Access Request&body=I am requesting access to the DAA. PLEASE CONFIRM THAT YOU ARE UBS INDEPENDENT';}

  render() {
    const {error, isOpen} = this.state;
    return (
      <div className="hide_scroll hide_scroll_chrome app flex-row align-items-center">
        <Container className= "hide_scroll hide_scroll_chrome">

          <Row className="hide_scroll hide_scroll_chrome justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody className="text-center">
                    <div>
                      <h4>Digital Audit Assistant Tool</h4>
                      <img src={network} height="240" width="240" id="network_img" alt=""/>
                    </div>
                  </CardBody>
                </Card>
                <Card className="p-4">
                  <CardBody className="hide_scroll hide_scroll_chrome text-center align-center">
                      { error &&
                          <ToastContainer
                              position="top-center"
                              autoClose={5000}
                              hideProgressBar={false}
                              newestOnTop={false}
                              closeOnClick={this.toggleToast}
                              rtl={false}
                              pauseOnFocusLoss
                              draggable
                              pauseOnHover
                            />
                      }
                      <Button type="button" onClick={this.submit_form} color="primary">Log In</Button>
                  </CardBody>
                  <CardBody className="hide_scroll hide_scroll_chrome text-center align-center">
                      { error &&
                          <ToastContainer
                              position="top-center"
                              autoClose={5000}
                              hideProgressBar={false}
                              newestOnTop={false}
                              closeOnClick={this.toggleToast}
                              rtl={false}
                              pauseOnFocusLoss
                              draggable
                              pauseOnHover
                            />
                      }
                      <Button type="button" onClick={this.submit_request} color="primary">Request Access</Button>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
