import React, { Component } from 'react';
import "./style.css"
import WorkProgramSelector from '../WorkProgramSelector/WorkProgramSelector.js';
import AssignmentProcess from '../AssignmentProcess/AssignmentProcess.js';
import { get } from "../../services/http";

class Assignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wprogOptionList: [],
      selectedWProg: null
    };
    this.changeSelect = this.changeSelect.bind(this);
  }

  componentDidMount(){
      get('/api/assign/get_workprograms/')
      .then( (res) => {
        const wprogOptionList = res.data.wprog_list[0];
        this.setState({wprogOptionList: wprogOptionList})
      });
  }

  changeSelect = (selectedOption) => {
    if (this.state.wprogOptionList.indexOf(selectedOption) > -1){
      this.setState({
          selectedWProg: selectedOption
      });
    }
  }
  
  render(){
    const title = 'Document Assignment';
    const subtitle = 'Audit Work Program';
    if (this.state.selectedWProg){
      return(
        <div>
          <WorkProgramSelector
            title={title}
            subtitle={subtitle}
            selectedWProg={this.state.selectedWProg}
            wprogOptionList={this.state.wprogOptionList}
            changeSelect={this.changeSelect}
          />
          <AssignmentProcess
            selectedWProg={this.state.selectedWProg}
          />   
        </div>
      )
    } else {
      return(
        <div>
          <WorkProgramSelector
            title={title}
            subtitle={subtitle}
            selectedWprog={this.state.selectedWProg}
            wprogOptionList={this.state.wprogOptionList}
            changeSelect={this.changeSelect}
          />
        </div>
      );
    }
  }
}

export default Assignment;
