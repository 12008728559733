import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
// import Loadable from "react-loading-overlay" ;
import ReactTable from "react-table";
import 'react-table/react-table.css' ;
import './style.css'
import axios from 'axios' ;
import token from '../../token.js' ;
import Select from 'react-select' ;
import { get , post, postAll } from "../../services/http";

class ProcessDefinition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      work_program: null,
      wprog_option_list: [],
      process_id: null,
      process_name: null,
      process_description: null,
      process_owner: null,
      business_division: null,
      relevant_policies: [],
      relevant_kpci: [],
      relevant_issues: []
    } ;
  };

  change_select_wprog = (selectedOption) => {
    postAll([
      {url: '/api/assign/get_policy_assignments/', data: {wprog_number: selectedOption.WProg_Number}},
      {url: '/api/assign/get_kpci_assignments/', data: {wprog_number: selectedOption.WProg_Number}},
      {url: '/api/assign/get_issue_assignments/', data: {wprog_number: selectedOption.WProg_Number}}
    ])
    .then((result) => {
      this.setState({
        work_program: selectedOption,
        wprog_number: selectedOption.WProg_Number,
        relevant_policies: result[0].data.assigned_policies,
        relevant_kpci: result[1].data.assigned_kpci,
        relevant_issues: result[2].data.assigned_issues
      });
    });
  }

  update_process_name = (event) => {
    this.setState({process_name: event.target.value})
  }

  update_process_description = (event) => {
    this.setState({process_description: event.target.value})
  }

  update_process_owner = (event) => {
    this.setState({process_owner: event.target.value})
  }

  update_business_division = (event) => {
    this.setState({business_division: event.target.value})
  }

  link_policy = (value) => {
    post('/api/walkthrough/post_policy_link/', {policy_id:value,process_id:this.state.process_id,status:'linked'})
    .then( res => {
      post('/api/walkthrough/get_policy_assignments_unlinked/', {process_id:this.state.process_id,wprog_number:this.state.work_program.value})
      .then ( res_unlinked => {
        this.setState({relevant_policies: res_unlinked.data.assigned_policies})
      })
      
        this.setState
      }
    )
  }

  link_kpci = (value) => {
    post('/api/walkthrough/post_kpci_link/', {kpci_id:value,process_id:this.state.process_id,status:'linked'})
    .then ( res => {})
  }
  link_issue = (value) => {
    post('/api/walkthrough/post_issue_link/', {issue_id:value,process_id:this.state.process_id,status:'linked'})
    .then ( res => {})
  }

  define_process = () => {
    post('/api/walkthrough/get_process_id/',{process_name:this.state.process_name})
    .then( res => {
      if (res.data.process_id.length > 0){
        alert(`This process name is already taken.\nPlease choose a different name or modify the process with the process modification form`)
      } else {
        post('/api/walkthrough/post_processes/',
        {
          wprog_number: this.state.work_program.WProg_Number,
          process_name: this.state.process_name,
          process_description: this.state.process_description,
          process_owner: this.state.process_owner,
          business_division: this.state.business_division 
        })
        .then( (res_id) => {
          // to be improved, there should be a functionality to get the id back in such situations
          post('/api/walkthrough/get_process_id/',{process_name: this.state.process_name})
          .then( res_id => {
            this.setState({process_id: res_id.data.process_id[0].Process_ID});
            alert(`The new process was sucessfully defined.\nYou can now proceed to link policies, controls and issues to the process.`);
          })
        })
      }
    }) 
  }

  reset_form = (event) => {
    this.setState({
      work_program: '',
      process_id: null,
      process_name: '',
      process_description: '',
      process_owner: '',
      business_division: ''
    });
  }

  componentDidMount(){
      get('/api/assign/get_workprograms/')
      .then((res) => {
        const wprog_option_list = res.data.wprog_list[0].map((element,index) => {element['value'] = element.WProg_Number; element['label'] = element.WProg_Title; return element});
        this.setState({wprog_option_list: wprog_option_list});
      })
  }

  renderProcessDefinition() {
    return (
      <div>
        <Card>
          <CardHeader>
            <strong> Define New Process </strong>
          </CardHeader>
          <CardBody>
            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
              <FormGroup row>
                <Col md="2">
                <Label htmlFor="work-program">Work Program</Label>
                </Col>
                <Col xs="12" md="9">
                  <Select
                      value={this.state.work_program}
                      options={this.state.wprog_option_list}
                      onChange={this.change_select_wprog}
                      onSelectResetsInput={false}
                      placeholder=""
                    />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md="2">
                  <Label htmlFor="-name"> Process Name</Label>
                </Col>
                <Col xs="12" md="9">
                  <Input type="text" id="process-name" value={this.state.process_name} onChange={this.update_process_name}/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md="2">
                  <Label htmlFor="process-description"> Process Description</Label>
                </Col>
                <Col xs="12" md="9">
                  <Input type="textarea" id="process-description" value={this.state.process_description} onChange={this.update_process_description}/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md="2">
                  <Label htmlFor="process-owner"> Process Owner</Label>
                </Col>
                <Col xs="12" md="9">
                  <Input type="text" id="process-owner" value={this.state.process_owner} onChange={this.update_process_owner}/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md="2">
                  <Label htmlFor="business-division">Business Division</Label>
                </Col>
                <Col xs="12" md="9">
                  <Input type="text" id="business-division" value={this.state.business_division} onChange={this.update_business_division}/>
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
          <CardFooter>
            <Button type="submit" size="sm" color="primary" onClick={this.define_process}><i className="fa fa-dot-circle-o"></i> Define Process  </Button>
            <Button type="Reset" size="sm" color="danger" onClick={this.reset_form}><i className="fa fa-ban"></i> Reset Form </Button>
          </CardFooter>
        </Card>
      </div>
    )
  }

  renderLinking() {
    return (
      <div>
        <Card>
          <CardHeader>
            <strong> Link Policies </strong>
          </CardHeader>
          <CardBody>
            <ReactTable
              ref={(r) => {this.relevant_policies = r}}
              data={this.state.relevant_policies}
              columns={[{Header:'ID', accessor: 'Policy_ID', maxWidth:100},
                        {Header:'Title', accessor: 'Title', maxWidth:450},
                        {Header:'Summary', accessor: 'Summary'},
                        {Header:'Applicability', accessor: 'Applicability', maxWidth:110},
                        {id: 'button', accessor: 'Policy_ID',
                          Cell: ({value}) => (<button onClick={() => {this.link_policy(value)}} > Link </button>),
                          maxWidth:70}
                        ]}
              defaultPageSize={5}
              className="-striped -highlight"
              />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <strong> Link KPCIs </strong>
          </CardHeader>
          <CardBody>
            <ReactTable
              ref={(r) => {this.relevant_kpci = r}}
              data={this.state.relevant_kpci}
              columns={[{Header:'ID', accessor: 'KPCI_ID', maxWidth:100},
                        {Header:'Title', accessor: 'KPCI_Title',maxWidth:500},
                        {Header:'Description', accessor: 'KPCI_Description'},
                        {id: 'button', accessor: 'KPCI_ID',
                          Cell: ({value}) => (<button onClick={() => {this.link_kpci(value)}}>Link</button>),
                          maxWidth:70}
                        ]}
              defaultPageSize={5}
              className="-striped -highlight"
              />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <strong> Link Issues </strong>
          </CardHeader>
          <CardBody>
            <ReactTable
              ref={(r) => {this.relevant_issues = r}}
              data={this.state.relevant_issues}
              columns={[{Header:'ID', accessor: 'Issue_ID', maxWidth:100},
                        {Header:'Title', accessor: 'Issue_Title', maxWidth:450},
                        // {Header:'Description', accessor: 'Issue_Description'},
                        {Header:'Highest Overall Rating', accessor: 'Issue_Highest_Rating',maxWidth:80,className:'center'},
                        {id: 'button', accessor: 'Issue_ID',
                          Cell: ({value}) => (<button onClick={() => {this.link_issue(value)}}>Link</button>),
                          maxWidth:70}
                        ]}
              defaultPageSize={5}
              className="-striped -highlight"
              />
          </CardBody>
        </Card>
      </div>
    ) 
  }

  render() {
    if(this.state.process_id){
      return (
        <div className="animated fadeIn">
            {this.renderProcessDefinition()}
            {this.renderLinking()}
        </div>
      )
    } else {
      return (
        <div className="animated fadeIn">
          {this.renderProcessDefinition()}
        </div>
      )
    }
  }
}

export default ProcessDefinition;
