import React, { Component } from 'react';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';

import "./style.css"
import ReactTable from "react-table";
//import 'react-table/react-table.css' ;
//import 'react-select/dist/react-select.css';
//test
// import axios from 'axios';
import { post } from "../../services/http";
const highlight_red = "highlight-red";
const highlight_amber = "highlight-amber";
const highlight_green = "highlight-green";

class AuditStrategy extends Component{

  constructor(props) {
    super(props);
    this.state = {
      auditStrategy: []
    };
  }

  componentWillReceiveProps(nextProps){
    if(this.props !== nextProps){
      post( '/api/assign/get_audit_strategy/',{wprog_number: nextProps.selectedWProg.value})
      .then( res => {
        this.setState({ auditStrategy: res.data.audit_strategy[0]});
      })
    }
  }

  componentDidMount(){
    post( '/api/assign/get_audit_strategy/',{wprog_number: this.props.selectedWProg.value})
    .then( res => {
      this.setState({ auditStrategy: res.data.audit_strategy[0]});
    })
  }

  backgroundColor = (color) => {
    switch(color) {
      case 'Green':
        return highlight_green;
      case 'Amber':
        return highlight_amber;
      case 'Red':
        return highlight_red;
      default:
        return null;
    }
  }

  render(){
    return(
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            <strong> Audit Strategy </strong>
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg="10">
                <ReactTable
                  data={this.state.auditStrategy}
                  columns={[
                    {Header: 'Risk Assessment',
                    columns: [
                      // {Header:'2017', accessor: 'risk_assessment_t_2',width: 100, className: "center"}, // Need to remove this column
                      {Header:'2021', accessor: 'risk_assessment_t_1',width: 100, className: "center"},
                      {Header:'2022', accessor: 'risk_assessment_t',width: 100, className: "center"}
                    ]},
                    {Header: 'Audit Depth',
                    columns: [
                      // {Header:'2017', accessor: 'audit_depth_t_2',width: 100, className: "center"}, // Need to remove this column
                      {Header:'Last Audit', accessor: 'audit_depth_t_1',width: 100, className: "center"},
                      {Header:'Last CA', accessor: 'audit_depth_t',width: 100, className: "center"}
                    ]},
                    {Header: 'Regulatory Assessment',
                    columns: [
                      {Header:'2019', accessor: 'regulatory_assessment_t_2', width: 100, className: "center",
                      getProps:
                      (state, rowInfo) => {
                        if(rowInfo){return {className: this.backgroundColor(rowInfo.original.regulatory_assessment_t_2)};}
                        else {return {};}
                      }
                      },
                      {Header:'2020', accessor: 'regulatory_assessment_t_1', width: 100, className: "center",
                      getProps:
                      (state, rowInfo) => {
                        if(rowInfo){return {className: this.backgroundColor(rowInfo.original.regulatory_assessment_t_1)};}
                        else {return {};}
                      }
                      },
                      {Header:'2021', accessor: 'regulatory_assessment_t', width: 100, className: "center",
                      getProps:
                      (state, rowInfo) => {
                        if(rowInfo){return {className: this.backgroundColor(rowInfo.original.regulatory_assessment_t)};}
                        else {return {};}
                      }
                      }
                    ]}
                  ]}
                  defaultPageSize={1}
                  className="-striped -highlight"
                  filterable={false}
                  showPagination={false}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    )
  }
}

export default AuditStrategy;
