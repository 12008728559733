import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row
} from 'reactstrap';

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import Select from 'react-select';
import Excel_icon from '../Icons/download_icon/Excel_icon.png';
import './style.css';
import axios from 'axios';
import { get, post, postAll } from "../../services/http";
import { downloadCSV } from '../CSVExport/CSVExport.js';
import { authentication_check } from '../Authentication/Authentication.js'

function TwoColLine(props) {
  return (
    <div>
      <Row>
        <Col xs={props.split}>
          <strong>{props.name}:</strong>
        </Col>
        <Col>
          {props.field !== "01 Jan 1900" ? props.field : "-"}
        </Col>
      </Row>
    </div>
  );
}

class IssuesLFR extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeVisTab: 1,
      FilterDate: moment(),
      FilterIssues: 0,
      LFRIssuesData: [],
      NewIssueData: [],
      loading_layer_isActive: false,
      IssueCount: [],
      selectedWProg: { value: 'all', label: 'Show all' },
      WProgOptionList: [],
    };
    this.getIssueCount = this.getIssueCount.bind(this);
    this.changeSelect = this.changeSelect.bind(this);
  }

  componentDidMount() {
      get('/api/issue/issue_get_workprograms/')
        .then((res) => {
          const WProgOptionList = res.data.wprog_list[0]
          this.setState({ WProgOptionList: WProgOptionList });
      });
      let promises = [
        { url :'/api/issue/get_count_lfr', data: { wprog_number: this.state.selectedWProg.value }},
        { url: '/api/issue/get_count_new', data: { wprog_number: this.state.selectedWProg.value }}
      ]

      postAll(promises).then((result) => {
        // console.log("res_lfr==>",result)
        this.setState({ LFRIssuesData: result[0].data.data_src[0], NewIssueData: result[1].data.data_src[0], selectedWProg: this.state.selectedWProg });
      })
      this.getIssueCount(this.state.selectedWProg);
  }

  getIssueCount(selectedWProg) {
    post('/api/issue/get_issue_count', { wprog_number: selectedWProg.value })
      .then((res) => {
        this.setState({ IssueCount: res.data.issue_count[0] })
    });
  }

  changeSelect(selectedWProg) {
    this.setState({ selectedWProg: selectedWProg });
    this.getIssueCount(selectedWProg);
    let promises = [
      { url :'/api/issue/get_count_lfr', data: { wprog_number: selectedWProg.value }},
      { url: '/api/issue/get_count_new', data: { wprog_number: selectedWProg.value }}
    ];
    postAll(promises).then((result) => {
      this.setState({ LFRIssuesData: result[0].data.data_src[0], NewIssueData: result[1].data.data_src[0], selectedWProg: selectedWProg });
    });
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <strong>Issue Count</strong>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="7">
                    <Select
                      value={this.state.selectedWProg}
                      options={this.state.WProgOptionList}
                      onChange={this.changeSelect}
                      onSelectResetsInput={false}
                      placeholder="Select a work program ..."
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="7">
                    <ReactTable
                      data={this.state.IssueCount}
                      columns={[
                        { Header: 'Rating', width: 80, accessor: 'rating', className: "center" },
                        { Header: 'Issues prior LFR', width: 120, accessor: 'Issues_prior_LFR', className: "center" },
                        { Header: 'Issues closed', width: 100, accessor: 'Issues_closed', className: "center" },
                        { Header: 'Issues new', width: 100, accessor: 'Issues_new', className: "center" },
                        { Header: 'Issues current LFR', width: 140, accessor: 'Issues_current_LFR', className: "center" },
                      ]}
                      defaultPageSize={3}
                      showPagination={false}
                      className="-striped -highlight"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Row>
                  <Col xs="12" lg="11">
                    <strong>Issues from last LFR (2017)</strong>
                  </Col>
                  <Col xs="12" lg="1">
                    <Button block color="link" onClick={() => {
                      var data = this.LFRIssuesData.getResolvedState().sortedData.map(x => { return x._original });
                      downloadCSV({
                        data: data,
                        separator: ';',
                        filename: 'filtered_export.csv',
                      });
                    }}>
                      <img src={Excel_icon} height="20" width="20" id="download_icon" alt="" />
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <ReactTable
                      ref={(r) => { this.LFRIssuesData = r }}
                      data={this.state.LFRIssuesData}
                      columns={[
                        { Header: 'Issue ID', width: 150, accessor: 'Issue_ID' },
                        { Header: 'Title', width: 300, accessor: 'issue_title' },
                        { Header: 'Rating', width: 80, accessor: 'rating', className: "center" },
                        { Header: 'Status', width: 80, accessor: 'issue_status', className: "center" },
                        { Header: 'Section', width: 80, accessor: 'lfr_section' },
                        { Header: 'WProg Title', width: 500, accessor: 'work_program_title' },
                      ]}
                      defaultPageSize={5}
                      className="-striped -highlight"
                      filterable={true}
                      defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id
                        return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1 : true
                      }}
                      SubComponent={row => {
                        return (
                          <div id="LFR-issues-subcomponent">
                            <Row>
                              <Col>
                                <Card>
                                  <CardBody>
                                    <TwoColLine name="Issue ID" field={row.original.issue_id} split="1" />
                                    <TwoColLine name="Issue Title" field={row.original.issue_title} split="1" />
                                    <TwoColLine name="WProg Title" field={row.original.work_program_title} split="1" />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        );
                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Row>
                  <Col xs="12" lg="11">
                    <strong>Newly assigned issues</strong>
                  </Col>
                  <Col xs="12" lg="1">
                    <Button block color="link" onClick={() => {
                      var data = this.NewIssueData.getResolvedState().sortedData.map(x => { return x._original });
                      downloadCSV({
                        data: data,
                        separator: ';',
                        filename: 'filtered_export.csv',
                      });
                    }}>
                      <img src={Excel_icon} height="20" width="20" id="download_icon" alt="" />
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <ReactTable
                      ref={(r) => { this.NewIssueData = r }}
                      data={this.state.NewIssueData}
                      columns={[
                        { Header: 'Issue ID', width: 150, accessor: 'issue_id' },
                        { Header: 'Title', width: 300, accessor: 'issue_title' },
                        { Header: 'Rating', width: 80, accessor: 'Rating', className: "center" },
                        { Header: 'Status', width: 80, accessor: 'Status', className: "center" },
                        { Header: 'WProg Title', width: 500, accessor: 'wprog_title' },
                      ]}
                      defaultPageSize={5}
                      className="-striped -highlight"
                      filterable={true}
                      defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id
                        return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1 : true
                      }}
                      SubComponent={row => {
                        return (
                          <div id="new-issues-subcomponent">
                            <Row>
                              <Col>
                                <Card>
                                  <CardBody>
                                    <TwoColLine name="Issue ID" field={row.original.issue_id} split="1" />
                                    <TwoColLine name="Issue Title" field={row.original.issue_title} split="1" />
                                    <TwoColLine name="WProg Title" field={row.original.wprog_title} split="1" />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        );
                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
export default IssuesLFR;
