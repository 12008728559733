import React, { Component } from 'react';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader
} from 'reactstrap';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import 'react-table/react-table.css';
import './style.css'
import axios from 'axios';
import {get, post } from "../../services/http";
import token from '../../token.js';
import IssueForm from '../IssueForm/IssueForm.js';
import {authentication_check} from '../Authentication/Authentication.js';

class IssueDefinition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wprog_option_list: [],
      selectedWProg: null,
      issue_due_date: null,
      issue_title: null,
      issue_statement: null,
      selectedRating: null,
      ubs_issue_owner: null,
      ubs_contact_last_discussed_with: null,
      owning_legal_entity: null,
      selectedStatus: null
    };
  };

  handleWProgChange = (selectedOption) => {this.setState({selectedWProg: selectedOption});}
  handleStatusChange = (selectedOption) => {this.setState({selectedStatus: selectedOption});}
  handleRatingChange = (selectedOption) => {this.setState({selectedRating: selectedOption});}

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({[name]:value});
  }

  handleSubmit = (event) => {
    if(this.checkSufficientInput()){
      post('/api/issue/check_title_existence/', {title: this.state.issue_title})
      .then ( res => {
        if (res.data.rows[0].n > 0){
          alert('There is already an issue with this title. If you want to update this issue, please go to the issue overview and select "update".');
        } else {
          post('/api/secure/issue/submit_issue/',{
            wprog_number: this.state.selectedWProg.value,
            title: this.state.issue_title,
            statement: this.state.issue_statement,
            rating: this.state.issue_rating,
            due_date: this.state.issue_due_date,
            status: this.state.issue_status,
            owning_legal_entity: this.state.owning_legal_entity,
            ubs_issue_owner: this.state.ubs_issue_owner,
            ubs_contact_last_discussed_with: this.state.ubs_contact_last_discussed_with,
            token: token.getToken()})
          .then( res => {
            if (res.data.status === 'uploaded'){
              alert('The isse was successfully defined.');
              this.resetForm();
            } else {
              alert('The issue could not be uploaded.');
            }
          })
        }
      }) 
    }
  }

  checkSufficientInput = () => {
    if(this.state.issue_title === '' || this.state.selectedWProg === null || this.state.selectedStatus === null){
      alert('Please provide at least the following information to define a new issue:\n\n - Issue Title\n - Work Program\n - Issue Status.\n\n The issue was not defined.')
      return false
    } else {
      return true
    }
  }

  resetForm = (event) => {
    this.setState({
      selectedWProg: null,
      issue_due_date: null,
      issue_title: '',
      issue_statement: '',
      selectedRating: null,
      ubs_issue_owner: '',
      ubs_contact_last_discussed_with: '',
      owning_legal_entity: '',
      selectedStatus: null
    });
  }

  componentDidMount(){
    get('/api/assign/get_workprograms/')
    .then((res) => {
      const wprog_option_list = res.data.wprog_list[0];
      this.setState({wprog_option_list: wprog_option_list});
    });
  }

  render(){
    return(
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            <strong> Define New Issue </strong>
          </CardHeader>
          <CardBody>
            <IssueForm
              issue_title={this.state.issue_title}
              selectedWProg={this.state.selectedWProg}
              wprog_option_list={this.state.wprog_option_list}
              issue_statement={this.state.issue_statement}
              issue_due_date={this.state.issue_due_date}
              selectedRating={this.state.selectedRating}
              owning_legal_entity={this.state.owning_legal_entity}
              ubs_issue_owner={this.state.ubs_issue_owner}
              ubs_last_contact_discussed_with={this.state.ubs_contact_last_discussed_with}
              selectedStatus={this.state.selectedStatus}
              handleInputChange={this.handleInputChange}
              handleRatingChange={this.handleRatingChange}
              handleWProgChange={this.handleWProgChange}
              handleStatusChange={this.handleStatusChange}
              />
          </CardBody>
          <CardFooter>
            <Button type="submit" size="sm" color="primary" onClick={this.handleSubmit}><i className="fa fa-dot-circle-o"></i>Submit</Button>
            <Button type="Reset" size="sm" color="danger" onClick={this.resetForm}><i className="fa fa-ban"></i>Reset Form</Button>
          </CardFooter>
        </Card>
      </div>
    )
  }
}

export default IssueDefinition;