import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row
} from 'reactstrap';

import 'react-datepicker/dist/react-datepicker.css';
import ReactTable from "react-table";
import 'react-table/react-table.css' ;
import Excel_icon from '../Icons/download_icon/Excel_icon.png' ;
import './style.css'
import Loadable from "react-loading-overlay" ;
import _ from 'lodash' ;
import {downloadCSV} from '../CSVExport/CSVExport.js';
import {authentication_check} from '../Authentication/Authentication.js';
import axios from 'axios' ;
import { post } from "../../services/http";

class TableFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loadingLayerIsActive: false,
      columns: []
    };
	}
 
  componentDidMount(){
      post(this.props.query_count)
      .then(res=> console.log("jasdkfasöj",res))


      post(this.props.query)
      .then( res => {
        var columns = [];
        if(res.data.rows.length > 0){
          columns = _.keys(res.data.rows[0]).map(x => {return({Header: x.replace(/_/g,' '), accessor: x, width: 150})});
        }
        this.setState({
          data: res.data.rows,
          columns: columns,
          loadingLayerIsActive: false});
        })
        .catch(function (error) {
          console.log(error);
        })
        
      this.setState({loadingLayerIsActive: true});
  }

  render() {
    return (
      <div className="animated fadeIn">
        <br/>
        <Row>
          <Col>
            <Loadable
              active={this.state.loadingLayerIsActive}
              spinner={true}
              text='Loading the data ...'
            >
            <Card>
              <CardHeader>
                <Row>
                  <Col xs="12" lg="11">
                    <strong>{this.props.title}</strong>
                  </Col>
                  <Col xs="12" lg="1">
                    <Button block color="link" onClick={() => {
                      console.log('test')
                      console.log(this.reactTable.getResolvedState().sortedData.map(x => {return x._original}))
                      downloadCSV({
                        data: this.reactTable.getResolvedState().sortedData.map(x => {return x._original}),
                        separator: ';',
                        filename: 'filtered_export.csv',
                      });
                    }}>
                      <img src={Excel_icon} height="20" width="20" id="download_icon" alt=""/>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <ReactTable
                  ref={(r) => {this.reactTable = r}}
                  data={this.state.data} 
                  columns={"prependColumns" in this.props ? this.props.prependColumns.concat(this.state.columns) : ("appendColumns" in this.props ?  this.state.columns.concat(this.props.appendColumns) : this.state.columns)}
                  defaultPageSize={this.props.defaultPageSize}
                  className="-striped -highlight"
                  filterable={true}
                  defaultFilterMethod= {(filter, row, column) => {
                    const id = filter.pivotId || filter.id
                    return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase())>-1 : true
                  }}
                  SubComponent={"SubComponent" in this.props ? row => this.props.SubComponent(row) : null}
                />
              </CardBody>
            </Card>
          </Loadable>
        </Col>
      </Row>
    </div>
    );
  }
}

export default TableFrame;
