import React, { Component } from 'react';
import 'react-table/react-table.css';
import Loadable from "react-loading-overlay";
// import axios from 'axios';
import { post, getAll } from "../../services/http";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Card, CardBody, CardHeader, CardGroup, Col, Row, FormGroup, Label } from 'reactstrap';


import 'react-table/react-table.css';
import './style.css';

import { authentication_check } from '../Authentication/Authentication.js';

class EYIssueForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      message: '',
      inputInvalid: false,
      loadingLayerIsActive: true,

      eytitle: '',
      eyIssueStatement: '',
      eyAuditTopic: '',
      eyContact: '',
      eyRating: '',
      eyEntity: '',
      eyType: '',
      eyWorKProgram: '',
      eyLfr: '',
      eyStatus: '',

      eyOE: '',
      eyTaxonomy: '',
      eyDueDate: '',
      eyIssueStatus: '',
      eyDescription: '',
      eyRiskStatement: '',
      eyRecommendation: '',
      eySortId: '',
      eyUbsResponse: '',
      eyOwner: '',
      eyOwnerTitle: '',
      eySupplementary: '',
      eyLfrNumber: '',
      eyLFRReference: '',
      eyCreatedBy: 'admin',
      eyModifiedBy: 'admin',

      ratingList: [<option value="0" key="0" >Select an option..</option>],
      entityList: [<option value="0" key="0" >Select an option..</option>],
      typeList: [<option value="0" key="0" >Select an option..</option>],
      workProgramList: [<option value="0" key="0" >Select an option..</option>],
      lfrList: [<option value="0" key="0" >Select an option..</option>],
      statusList: [<option value="0" key="0" >Select an option..</option>],

    };

    //this.validForm = this.validForm.bind(this);
    this.handleKeyStroke = this.handleKeyStroke.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    let requests = [];
    requests.push({
      url: "/api/issue/issue_get_lfr/"
    },{
      url: "/api/issue/issue_get_ratings/"
    },{
      url: "/api/issue/issue_get_bank_entities/"
    },{
      url: "/api/issue/issue_get_issue_sources/"
    },{
      url: "/api/issue/issue_get_issue_status_with_bank/"
    },{
      url: "/api/issue/issue_get_workprograms/"
    });
    getAll(requests).then((result, res_lfr, res_ratings, res_entities, res_type, res_status, res_workprograms) => {
      const lfrOptionList = result[0].data.lfr_list[0]?.map(item => {
        return (<option value={item.section_lfr} key={item.section_lfr}>{item.LFR_section}</option>)
      });
      const newLFRList = this.state.lfrList.concat(lfrOptionList);

      // Rating List
      const ratingsOptionList = result[1].data.ratings_list[0]?.map(item => {
        return (<option value={item.rating} key={item.rating}>{item.rating}</option>)
      });
      const newRatingsList = this.state.ratingList.concat(ratingsOptionList);

      //Entities List
      const entitiesOptionList = result[2].data.entities_list[0]?.map(item => {
        return (<option value={item.bank_entitiy} key={item.bank_entitiy}>{item.bank_entitiy}</option>)
      });
      const newEntityList = this.state.entityList.concat(entitiesOptionList);

      //EY Type List
      const typOptionList = result[3].data.type_list[0]?.map(item => {
        return (<option value={item.issue_source_id} key={item.issue_source_id}>{item.issue_source_id}</option>)
      });
      const newTypeList = this.state.typeList.concat(typOptionList);

      //EY Status with Bank List
      const statusOptionList = result[4].data.status_list[0]?.map(item => {
        return (<option value={item.issue_status_name} key={item.issue_status_name}>{item.issue_status_name}</option>)
      });
      const newStatusList = this.state.statusList.concat(statusOptionList);

      //Work Program List
      const workOptionList = result[5].data.wprog_list[0]?.map(item => {
        if (item.value !== "all") {
          return (<option value={item.value} key={item.value}>{item.label}</option>)
        }
        return;
      });
      const newWorkList = this.state.workProgramList.concat(workOptionList);

      this.setState({
        lfrList: newLFRList,
        ratingList: newRatingsList,
        entityList: newEntityList,
        typeList: newTypeList,
        statusList: newStatusList,
        workProgramList: newWorkList,
        loadingLayerIsActive: false
      });
    });

    //   axios.all([
    //     axios.get(process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/issue/issue_get_lfr/'),
    //     axios.get(process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/issue/issue_get_ratings/'),
    //     axios.get(process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/issue/issue_get_bank_entities/'),
    //     axios.get(process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/issue/issue_get_issue_sources/'),
    //     axios.get(process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/issue/issue_get_issue_status_with_bank/'),
    //     axios.get(process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/issue/issue_get_workprograms/'),
    //   ]).then(axios.spread((res_lfr, res_ratings, res_entities, res_type, res_status, res_workprograms) => {
    //     console.log(res_status);
    //     // LRF Sections
    //     const lfrOptionList = res_lfr.data.lfr_list[0].map(item => {
    //       return (<option value={item.section_lfr} key={item.section_lfr}>{item.LFR_section}</option>)
    //     });
    //     const newLFRList = this.state.lfrList.concat(lfrOptionList);

    //     // Rating List
    //     const ratingsOptionList = res_ratings.data.ratings_list[0].map(item => {
    //       return (<option value={item.rating} key={item.rating}>{item.rating}</option>)
    //     });
    //     const newRatingsList = this.state.ratingList.concat(ratingsOptionList);

    //     //Entities List
    //     const entitiesOptionList = res_entities.data.entities_list[0].map(item => {
    //       return (<option value={item.bank_entitiy} key={item.bank_entitiy}>{item.bank_entitiy}</option>)
    //     });
    //     const newEntityList = this.state.entityList.concat(entitiesOptionList);

    //     //EY Type List
    //     const typOptionList = res_type.data.type_list[0].map(item => {
    //       return (<option value={item.issue_source_id} key={item.issue_source_id}>{item.issue_source_id}</option>)
    //     });
    //     const newTypeList = this.state.typeList.concat(typOptionList);

    //     //EY Status with Bank List
    //     const statusOptionList = res_status.data.status_list[0].map(item => {
    //       return (<option value={item.issue_status_name} key={item.issue_status_name}>{item.issue_status_name}</option>)
    //     });
    //     const newStatusList = this.state.statusList.concat(statusOptionList);

    //     //Work Program List
    //     const workOptionList = res_workprograms.data.wprog_list[0].map(item => {
    //       if (item.value !== "all") {
    //         return (<option value={item.value} key={item.value}>{item.label}</option>)
    //       }
    //       return;
    //     });
    //     const newWorkList = this.state.workProgramList.concat(workOptionList);

    //     this.setState({
    //       lfrList: newLFRList,
    //       ratingList: newRatingsList,
    //       entityList: newEntityList,
    //       typeList: newTypeList,
    //       statusList: newStatusList,
    //       workProgramList: newWorkList,
    //     });

    //   }))


    if (!this.props.createNewIssue) {
      var issueID = this.props.eyIssueInformation.ey_issue_id;
      post('/api/issue/get_eyissue_data/', { eyIssueId: issueID })
        .then((res) => {
          console.log(res.data);
          var ey_issue = res.data.ey_issue[0]
          if (ey_issue.length > 0) {
            this.setState({
              eytitle: ey_issue[0].ey_issue_title,
              eyIssueStatement: ey_issue[0].ey_issue_statement,
              eyAuditTopic: ey_issue[0].ey_issue_audit_topic,
              eyContact: ey_issue[0].ey_contact,
              eyRating: ey_issue[0].ey_issue_rating,
              eyEntity: ey_issue[0].ey_issue_entity,
              eyType: ey_issue[0].ey_issue_type,
              eyWorKProgram: ey_issue[0].ey_issue_wprog,
              eyLfr: ey_issue[0].ey_issue_lfr_section, //+ ': ' + ey_issue[0].section_title_lfr,
              eyStatus: ey_issue[0].ey_issue_status_with_bank,
            });
          }
        });
    }

  }

  handleCloseModal() {
    this.props.hideEYIssue();
  }

  handleSubmit() {

    if (this.state.eytitle === "" || this.state.eyIssueStatement === "" || this.state.eyAuditTopic === ""
      || this.state.eyContact === "" || this.state.eyRating === "" || this.state.eyEntity === "" || this.state.eyType === ""
      || this.state.eyWorKProgram === "" || this.state.eyLfr === "" || this.state.eyStatus === "") {
      this.setState({
        showAlert: true,
        message: 'Please fill all the mandatory fields!!',
      });
    } else {

      var issueId = this.props.createNewIssue ? '' : this.props.eyIssueInformation.ey_issue_id;

      var data = {
        eyIssueId: issueId,
        eytitle: this.state.eytitle,
        eyIssueStatement: this.state.eyIssueStatement,
        eyAuditTopic: this.state.eyAuditTopic,
        eyContact: this.state.eyContact,
        eyRating: this.state.eyRating,
        eyEntity: this.state.eyEntity,
        eyType: this.state.eyType,
        eyWorKProgram: this.state.eyWorKProgram,
        eyLfr: this.state.eyLfr,
        eyStatus: this.state.eyStatus,

        eyOE: this.state.eyOE,
        eyTaxonomy: this.state.eyTaxonomy,
        //eyDueDate: this.state.eyDueDate,
        eyDueDate: new Date('2019-10-18'),
        eyIssueStatus: this.state.eyIssueStatus,
        // eyDescription: this.state.eyDescription,
        eyRiskStatement: this.state.eyRiskStatement,
        eyRecommendation: this.state.eyRecommendation,
        eySortId: this.state.eySortId,
        eyUbsResponse: this.state.eyUbsResponse,
        eyOwner: this.state.eyOwner,
        eyOwnerTitle: this.state.eyOwnerTitle,
        eySupplementary: this.state.eySupplementary,
        eyLfrNumber: this.state.eyLfrNumber,
        eyLFRReference: this.state.eyLFRReference,
        eyCreatedBy: this.state.eyCreatedBy,
        eyModifiedBy: this.state.eyModifiedBy,
      };

      if (this.props.createNewIssue) {
        post('/api/issue/create_ey_issue', data)
          .then((res) => {
            console.log(res.data);
            this.handleCloseModal();
          });
      } else {
        post('/api/issue/update_ey_issue', data)
          .then((res) => {
            console.log(res.data);
            this.handleCloseModal();
          });
      }

    }

  }

  handleKeyStroke(event) {
    if (event.target.value !== "0") {
      let newState = {};
      newState[event.target.id] = event.target.value;
      this.setState(newState);
      this.setState({
        showAlert: false,
      });
    }

  }

  render() {
    console.log(this.state);
    return (
      <div>
        <Modal size='lg' className={'modal-width'} isOpen={true} >
          <ModalHeader>
            New EY Issue
          </ModalHeader>
          <ModalBody>
            <Loadable
              active={this.state.loadingLayerIsActive}
              spinner={true}
              text='Loading ...'
            >
              <Row className="justify-content-center">
                <Col md="12">
                  <CardGroup>
                    <Card className="p-4">
                      <CardHeader>
                        <Row>
                          <Col>
                            <strong>EY Issues </strong>
                          </Col>
                          <Col md="3">
                            <Button variant="secondary" className='btn-align' onClick={this.handleCloseModal}>
                              Close
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col xs="6">
                            <FormGroup>
                              <Label htmlFor="eytitle">Issue Title</Label>
                              <Input type="text" id="eytitle" value={this.state.eytitle} onChange={this.handleKeyStroke} />
                            </FormGroup>
                          </Col>
                          <Col xs="3">
                            <FormGroup>
                              <Label htmlFor="title">Rating</Label>
                              <Input type="select" placeholder="Rating" value={this.state.eyRating} id="eyRating" onChange={this.handleKeyStroke}>
                                {this.state.ratingList}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col xs="3">
                            <FormGroup>
                              <Label htmlFor="title">Entity</Label>
                              <Input type="select" placeholder="Entity" value={this.state.eyEntity} id="eyEntity" onChange={this.handleKeyStroke}>
                                {this.state.entityList}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            <FormGroup>
                              <Label htmlFor="eyIssueStatement">Issue Statement</Label>
                              <Input type="text" id="eyIssueStatement" value={this.state.eyIssueStatement} onChange={this.handleKeyStroke} />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="3">
                            <FormGroup>
                              <Label htmlFor="title">Issue Type</Label>
                              <Input type="select" placeholder="Issue Type" value={this.state.eyType} id="eyType" onChange={this.handleKeyStroke}>
                                {this.state.typeList}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col xs="3">
                            <FormGroup>
                              <Label htmlFor="title">Work Program</Label>
                              <Input type="select" placeholder="Work Program" value={this.state.eyWorKProgram} id="eyWorKProgram" onChange={this.handleKeyStroke}>
                                {this.state.workProgramList}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col xs="3">
                            <FormGroup>
                              <Label htmlFor="title">LFR Section</Label>
                              <Input type="select" placeholder="LFR Section" value={this.state.eyLfr} id="eyLfr" onChange={this.handleKeyStroke}>
                                {this.state.lfrList}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col xs="3">
                            <FormGroup>
                              <Label htmlFor="eyAuditTopic">Audit Topic</Label>
                              <Input type="text" id="eyAuditTopic" value={this.state.eyAuditTopic} onChange={this.handleKeyStroke} />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6">
                            <FormGroup>
                              <Label htmlFor="eyContact">EY Contact</Label>
                              <Input type="text" id="eyContact" value={this.state.eyContact} onChange={this.handleKeyStroke} />
                            </FormGroup>
                          </Col>
                          <Col xs="6">
                            <FormGroup>
                              <Label htmlFor="title">Issue status with Bank</Label>
                              <Input type="select" placeholder="Issue status with Bank" value={this.state.eyStatus} id="eyStatus" onChange={this.handleKeyStroke}>
                                {this.state.statusList}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </CardGroup>
                </Col>
              </Row>
              <div>
                {this.state.showAlert && <div className='alert alert-danger' role='alert'> {this.state.message} </div>}
              </div>
            </Loadable>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Cancel
            </Button>
            <Button color="primary" disabled={this.state.inputInvalid} onClick={this.handleSubmit}>
              Save Changes
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default EYIssueForm;
