import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
// import Loadable from "react-loading-overlay" ;
import ReactTable from "react-table";
import 'react-table/react-table.css' ;
import './style.css'
import axios from 'axios' ;
import token from '../../token.js' ;
import Select from 'react-select' ;
import { getAll, post, postAll } from "../../services/http";

class ProcessModification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wprog_option_list: [],
      pol_option_list: [],
      kpci_option_list: [],
      issue_option_list: [],
      work_program: null,
      process: null,
      process_description: null,
      process_owner: null,
      business_division: null,
      linked_policies: [],
      linked_kpci: [],
      linked_issues: []
    } ;
  };

  change_select_wprog = (selectedOption) => {
    postAll([
      {url: '/api/assign/get_policy_assignments/', data: {wprog_number: selectedOption.value}},
      {url: '/api/assign/get_kpci_assignments/', data: {wprog_number: selectedOption.value}},
      {url: '/api/assign/get_issue_assignments/', data: {wprog_number: selectedOption.value}}
    ])
    .then((result) => {
      this.setState({
        work_program: selectedOption,
        pol_option_list: result[0].data.assigned_policies,
        kpci_option_list: result[1].data.assigned_kpci,
        issue_option_list: result[2].data.assigned_issues
      });
    });
  }

  change_select_process_name = (selectedOption) => {
    postAll([
      {url: '/api/walkthrough/get_process_information/', data: {process_id:selectedOption.value}},
      {url: '/api/walkthrough/get_policy_linked/', data: {process_id:selectedOption.value}},
      {url: '/api/walkthrough/get_kpci_linked/', data: {process_id:selectedOption.value}},
      {url: '/api/walkthrough/get_issue_linked/', data: {process_id:selectedOption.value}}
    ])
    .then((result) => {
      this.setState({
        process: selectedOption,
        process_description: result[0].data.process_info[0].Process_Description,
        process_owner: result[0].data.process_info[0].Process_Owner,
        business_division: result[0].data.process_info[0].Business_Division,
        linked_policies: result[1].data.linked_policies,
        linked_kpci: result[2].data.linked_kpci,
        linked_issues: result[3].data.linked_issues   
      });
      var selectedOptionWprog = {
        value: result[0].data.process_info[0].WProg_Number,
        label: result[0].data.process_info[0].WProg_Title
      };
      this.change_select_wprog(selectedOptionWprog);
    });
  }

  update_process_name = (event) => {this.setState({process_name: event.target.value})}
  update_process_description = (event) => {this.setState({process_description: event.target.value})}
  update_process_owner = (event) => {this.setState({process_owner: event.target.value})}
  update_business_division = (event) => {this.setState({business_division: event.target.value})}

  unlink_policy = (policy_id) => {
    post('/api/walkthrough/post_policy_link/', {policy_id:policy_id,process_id:this.state.process.value,status:'unlinked'})
    .then ( res => {})
  }

  unlink_kpci = (kpci_id) => {
    post('/api/walkthrough/post_kpci_link/', {kpci_id:kpci_id,process_id:this.state.process.value,status:'unlinked'})
    .then ( res => {})
  }

  unlink_issue = (issue_id) => {
    post('/api/walkthrough/post_issue_link/', {issue_id:issue_id,process_id:this.state.process.value,status:'unlinked'})
    .then ( res => {})
  }

  modify_process = () => {
    post('/api/walkthrough/modify_processes/',
    {
      wprog_number: this.state.work_program.value,
      process_id: this.state.process.value,
      process_description: this.state.process_description,
      process_owner: this.state.process_owner,
      business_division: this.state.business_division 
    })
    .then( (res) => {
      alert('The process was sucessfully modified');
    })
  }

  componentDidMount(){
      getAll([
        {url: '/api/assign/get_workprograms/'},
        {url: '/api/walkthrough/get_definition_processes/'}
      ])
      .then((res_wprog, res_proc) => {
        const wprog_option_list = res_wprog.data.wprog_list.map((element,index) => {element['value'] = element.WProg_Number; element['label'] = element.WProg_Title; return element});
        this.setState({
          process_option_list: res_proc.data.processes,
          wprog_option_list: wprog_option_list
        });
      });
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            <strong> Modify Process </strong>
          </CardHeader>
          <CardBody>
            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
              <FormGroup row>
                <Col md="2">
                <Label htmlFor="process-id">Process Name</Label>
                </Col>
                <Col xs="12" md="9">
                  <Select
                      value={this.state.process}
                      options={this.state.process_option_list}
                      onChange={this.change_select_process_name}
                      onSelectResetsInput={false}
                      placeholder="Select a process ..."
                    />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md="2">
                <Label htmlFor="work-program">Work Program</Label>
                </Col>
                <Col xs="12" md="9">
                  <Select
                      value={this.state.work_program}
                      options={this.state.wprog_option_list}
                      onChange={this.change_select_wprog}
                      onSelectResetsInput={false}
                      placeholder=""
                    />
                </Col>
              </FormGroup>
              {/* <FormGroup row>
                <Col md="2">
                  <Label htmlFor="process-name"> Process Name</Label>
                </Col>
                <Col xs="12" md="9">
                  <Input type="text" id="process-name" value={this.state.process_name} onChange={this.update_process_name}/>
                </Col> */}
              {/* </FormGroup> */}
              <FormGroup row>
                <Col md="2">
                  <Label htmlFor="process-description"> Process Description</Label>
                </Col>
                <Col xs="12" md="9">
                  <Input type="textarea" id="process-description" value={this.state.process_description} onChange={this.update_process_description}/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md="2">
                  <Label htmlFor="process-owner"> Process Owner</Label>
                </Col>
                <Col xs="12" md="9">
                  <Input type="text" id="process-owner" value={this.state.process_owner} onChange={this.update_process_owner}/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md="2">
                  <Label htmlFor="business-division">Business Division</Label>
                </Col>
                <Col xs="12" md="9">
                  <Input type="text" id="business-division" value={this.state.business_division} onChange={this.update_business_division}/>
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
          <CardFooter>
            <Button type="submit" size="sm" color="primary" onClick={this.modify_process}><i className="fa fa-dot-circle-o"></i> Modify Process  </Button>
            {/* <Button type="Reset" size="sm" color="danger"><i className="fa fa-ban"></i> Reset</Button> */}
          </CardFooter>
        </Card>
        <Card>
          <CardHeader>
            <strong> Linked Policies </strong>
          </CardHeader>
          <CardBody>
            <ReactTable
              ref={(r) => {this.linked_policies = r}}
              data={this.state.linked_policies}
              columns={[{Header:'ID', accessor: 'Policy_ID', maxWidth:100},
                        {Header:'Title', accessor: 'Title', maxWidth:450},
                        {Header:'Summary', accessor: 'Summary'},
                        {Header:'Applicability', accessor: 'Applicability', maxWidth:110},
                        {id: 'button', accessor: 'Policy_ID',
                          Cell: ({value}) => (<button onClick={() => {this.unlink_policy(value)}}>Unlink</button>),
                          maxWidth:70}
                        ]}
              defaultPageSize={5}
              className="-striped -highlight"
              // filterable={true}
              // defaultFilterMethod= {(filter, row, column) => {
              //       const id = filter.pivotId || filter.id
              //       return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase())>-1 : true
              //     }}
              />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <strong> Linked KPCIs </strong>
          </CardHeader>
          <CardBody>
            <ReactTable
              ref={(r) => {this.linked_kpci = r}}
              data={this.state.linked_kpci}
              columns={[{Header:'ID', accessor: 'KPCI_ID', maxWidth:100},
                        {Header:'Title', accessor: 'KPCI_Title',maxWidth:500},
                        {Header:'Description', accessor: 'KPCI_Description'},
                        {id: 'button', accessor: 'KPCI_ID',
                          Cell: ({value}) => (<button onClick={() => {this.unlink_kpci(value)}}>Unlink</button>),
                          maxWidth:70}
                        ]}
              defaultPageSize={5}
              className="-striped -highlight"
              // filterable={true}
              // defaultFilterMethod= {(filter, row, column) => {
              //       const id = filter.pivotId || filter.id
              //       return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase())>-1 : true
              //     }}
              />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <strong> Linked Issues </strong>
          </CardHeader>
          <CardBody>
            <ReactTable
              ref={(r) => {this.linked_issues = r}}
              data={this.state.linked_issues}
              columns={[{Header:'ID', accessor: 'Issue_ID', maxWidth:100},
                        {Header:'Title', accessor: 'Issue_Title', maxWidth:450},
                        // {Header:'Description', accessor: 'Issue_Description'},
                        {Header:'Highest Overall Rating', accessor: 'Issue_Highest_Rating',maxWidth:80,className:'center'},
                        {id: 'button', accessor: 'Issue_ID',
                          Cell: ({value}) => (<button onClick={() => {this.unlink_issue(value)}}>Unlink</button>),
                          maxWidth:70}
                        ]}
              defaultPageSize={5}
              className="-striped -highlight"
              // filterable={true}
              // defaultFilterMethod= {(filter, row, column) => {
              //       const id = filter.pivotId || filter.id
              //       return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase())>-1 : true
              //     }}
              />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default ProcessModification;
