import React, { Component } from 'react';
import {
  Card,
  Button,
  CardBody,
  CardHeader,
  Col,
  Row
} from 'reactstrap';


import network from '../Images/LandingPage/network.png';
//import Execution from '../Images/LandingPage/Execution.png';
//import Puzzle from '../Images/LandingPage/Puzzle.png';
//import PDF_File from '../Images/LandingPage/userguide.pdf';
import Teams_icon from '../Icons/download_icon/Teams.png';
//import Linking_worlds from '../Images/LandingPage/Linking_worlds.png';
import "./style.css";
import 'react-table/react-table.css';
//import 'react-select/dist/react-select.css';


class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = null;

    this.openUserGuide = this.openUserGuide.bind(this);
  }

  openUserGuide() {
    window.open('https://teams.microsoft.com/l/team/19%3adzTPMVVhiHi58NqAbmldZBTnLYxhEzZE1LU6qCRIoco1%40thread.tacv2/conversations?groupId=c4b472bd-7e23-4730-8ee0-bbb3a48fb053&tenantId=5b973f99-77df-4beb-b27d-aa0c70b8482c');
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card id="top-card">
              <CardBody id="title">
                <img src={network} height="240" width="240" id="network_img" alt="" />
                <h2>Digital Audit Assistant</h2>
                <h6> Version 2.0.0</h6>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card id="card">
              <CardHeader>
                <strong>User guidance</strong>
              </CardHeader>
              <CardBody>
                This user guidance provides support for the DAA and outlines the key functions of the tool.
  In case you need more information about certain functionalities, or you have malfunction to report, please get in contact with the DAA mailbox (administrator / development team): &nbsp;
  <a href="mailto:EY_DAA_Admin.CH.GID@ey.net"><strong> EY_DAA_Admin.CH.GID@ey.net</strong></a>
                <br></br>
                <br></br>
                To access user guidance and training information visit our MS teams site:
                <Button block color="link" style={{marginLeft: "auto" }} onClick={() => { this.openUserGuide(); }}>
                  <img src={Teams_icon} height="100" width="100" id="download_icon" alt="" />
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/*<Row>
          <Col>
            <Card id="card">
              <CardHeader>
                <strong>Audit Challenge</strong>
              </CardHeader>
              <CardBody>
                The transparent and structured dependencies between e.g. regulatory requirements, policy documents, risks, assessment of risks & key controls, issues and operational loss events are typically not consistently and coherently documented in bank systems.
              </CardBody>
            </Card>
          </Col>
    </Row>*/}
        <Row>
          <Col>
            <Card id="card">
              <CardHeader>
                <strong>Motivation and Benefits </strong>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    The Digital Audit Assistant (DAA), captures and supports the F2B regulatory audit execution process by applying advanced analytics and visualization.
                  </Col>
                </Row>
                <Row>
                  <br />
                </Row>
                <Row>
                  <Col lg="6">
                    <strong> Motivation </strong>
                  </Col>
                  <Col lg="6">
                    <strong> Benefits </strong>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <ul>
                      <li> Transparently capture our collective know-how </li>
                      <li> Create greater insights to support sound decision taking </li>
                      <li> Boost efficiency by reducing work effort on routine activities </li>
                      <li> Detect dependencies based on concept of similarity </li>
                    </ul>
                  </Col>
                  <Col lg="6">
                    <ul>
                      <li> Link regulatory and banking world </li>
                      <li> Provide an integrated regulatory audit dashboard </li>
                      <li> Availability of up-to-date information </li>
                      <li> Recommend missing data links </li>
                      <li> Visualize relationship</li>
                    </ul>
                  </Col>
                </Row>
                {/*<Row>
                  <Col lg="6">
                    <img src={Execution} height="230" width="240" id="execution" alt="" className="center" />
                  </Col>
                  <Col lg="6">
                    <img src={Puzzle} height="230" width="300" id="puzzle" alt="" />
                  </Col>
                </Row>
                */}
              </CardBody>

            </Card>
            {/*<Card id="card">
              <CardHeader>
                <strong> Linking the different worlds </strong>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    The DAA brings together relevant audit data and builds up on a recommendation engine that supports the construction of dependencies.
                  </Col>
                </Row>
                <Row>
                  <br />
                </Row>
                <Row>
                  <Col>
                    <img src={Linking_worlds} height="300" width="500" id="linking" alt="" />
                  </Col>
                </Row>
              </CardBody>
            </Row>
              */}
          </Col>
        </Row>
      </div>
    );
  }
}

export default LandingPage;
