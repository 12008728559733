import {
  Network,
  Assignment,
  Dashboard,
  LandingPage,
  PolicyExplorer,
  ProcessOverview,
  ProcessView,
  ProcessModification,
  ProcessDefinition,
  PdfUpload,
  IssueExplorer,
  IssueCount,
  IssueOverview,
  IssueUpdate,
  IssueDefinition,
  KPCIExplorer,
  AppMaintenance,
  AccessDenied,
  EYIssue,
  LFRSection
} from './views';
import Full from './containers/Full';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: Full },
  { path: '/home', name: 'Home', component: LandingPage},
  { path: '/explore/policy', name: 'Policy Explorer', component: PolicyExplorer},
  { path: '/explore/kpci', name: 'KPCI Explorer', component: KPCIExplorer},
  { path: '/explore/issue', name: 'Issue Explorer', component: IssueExplorer},
  { path: '/eyissue/overview', name: 'EY Issue', component: EYIssue},
  { path: '/explore/network', name: 'Network', component: Network},
  { path: '/lfrsection/production', name: 'LFR Section', component: LFRSection},
  { path: '/assign/assignment', name: 'Assignment', component: Assignment},
  { path: '/assign/dashboard', name: 'Dashboard', component: Dashboard},
  { path: '/issue/count', name: 'Issue Count', component: IssueCount},
  { path: '/issue/overview', name: 'Issue Overview', component: IssueOverview},
  { path: '/issue/update', name: 'Issue Update', component: IssueUpdate},
  { path: '/issue/definition', name: 'Issue Definition', component: IssueDefinition},
  { path: '/admin/pdfupload', name: 'PDF Uploader', component: PdfUpload},
  { path: '/walkthrough/overview', name: 'Process Overview', component: ProcessOverview},
  { path: '/walkthrough/view', name: 'Process View', component: ProcessView},
  { path: '/walkthrough/modification', name: 'Process Modification', component: ProcessModification},
  { path: '/walkthrough/definition', name: 'Process Definition', component: ProcessDefinition},
  { path: '/admin/maintenance', name: 'App Maintenance', component: AppMaintenance },
  { path: '/access_denied', name: 'Access Denied', component: AccessDenied},
];

export default routes;
