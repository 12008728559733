// This file contains the function getToken which provides the saved token
// from the Browser memory (if available)
import Cookies from 'js-cookie';


const setToken = function(token){
  sessionStorage.setItem('jwt', token) ;
}

const getToken = function(){
  return Cookies.get('jwt') ;
}

export default  {
  setToken,
  getToken
}