import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import Excel_icon from '../Icons/download_icon/Excel_icon.png';
import './style.css'
import axios from 'axios';
import { downloadCSV } from '../CSVExport/CSVExport.js';
import Select from 'react-select';
import _ from 'lodash';
import {get, post, postAll } from "../../services/http";
import { authentication_check } from '../Authentication/Authentication.js';
import token from '../../token.js';

import LFRSectionForm from '../LFRSectionForm/LFRSectionForm';

function isInList(num, list) {
  // List is an Array()
  var result = false;
  for (var i in list) {
    if (num == list[i]) { result = true }
  }
  return result
}


class LFRSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entity1: '',
      entity2: 'UBS CH AG',
      selectedlfr: { value: 'all', label: 'Show all' },
      lfrOptionList: [],
      lfrsectionData: [],
      lfrsectionCountEntity1: [],
      columnsCountEntity1: [],
      lfrsectionCountEntity2: [],
      columnsCountEntity2: [],
      columnsData: [],
      modifyActions: false,
      formLFRIssue: false,
      username: '',
      lfrIssueInformation: []
    };

    this.changeSelect = this.changeSelect.bind(this);
    this.getLFRSectionsCount = this.getLFRSectionsCount.bind(this);
    this.getLFRSections = this.getLFRSections.bind(this);
    this.editLFRIssue = this.editLFRIssue.bind(this);
    this.handleHideLFRSectionForm = this.handleHideLFRSectionForm.bind(this);
  }

  componentDidMount() {
    get('/api/issue/issue_get_lfr/')
    .then((res) => {
      const lfrOptionList = res.data.lfr_list[0].map(item => {
        return ({
          value: item.section_lfr,
          label: item.LFR_section
        })
      });
      this.setState({ lfrOptionList: lfrOptionList });
    });

    this.getLFRSections(this.state.selectedlfr);
    this.getLFRSectionsCount();

    // get('/api/secure/')
    // .then(res => {
    //   console.log(res.data);
    //   this.setState({ username: res.data.name });
    //   if (res.data.role !== "READ") {
    //     this.setState({ modifyActions: true });
    //   }
    // });
  }

  editLFRIssue(lfrissue) {
    console.log(lfrissue);
    if (this.state.modifyActions) {
      this.setState({ formLFRIssue: true, lfrIssueInformation: lfrissue });
    } else {
      alert("Access Denied! You don't have permission to access this action. ")
    }

  }

  getLFRSectionsCount() {
    postAll([
      { url: '/api/lfrsection/count_lfr_issues/', data: { entity: this.state.entity1 }},
      { url: '/api/lfrsection/count_lfr_issues/', data: { entity: this.state.entity2 }}
    ]).then((result) => {
      var columnsCountEntity1 = [];

      columnsCountEntity1 = _.keys(result[0].data.lfrsection_count[0][0]).map(x => {
        return ({
          Cell: ({ value }) => {
            return (value === null ? 0 : value)
          },
          Header: x.replace(/_/g, ' '), accessor: x, width: x.lenght + 50, className: 'text-align',
        })
      });

      var columnsCountEntity2 = [];

      columnsCountEntity2 = _.keys(result[1].data.lfrsection_count[0][0]).map(x => {
        return ({
          Cell: ({ value }) => {
            return (value === null ? 0 : value)
          },
          Header: x.replace(/_/g, ' '), accessor: x, width: x.lenght + 50, className:'text-align',
        })
      });

      /* entity1_data = res_entity2.data.lfrsection_count[0].map(item => {
        return({

        })
      }) */

      this.setState({ columnsCountEntity1: columnsCountEntity1, lfrsectionCountEntity1: result[0].data.lfrsection_count[0] })
      this.setState({ columnsCountEntity2: columnsCountEntity2, lfrsectionCountEntity2: result[1].data.lfrsection_count[0] })
    });
  }

  getLFRSections(selectedlfr) {
    post('/api/lfrsection/get_lfr_issues/', { lfr_section: selectedlfr.value })
      .then((res) => {

        var columnsData = [];

        columnsData = _.keys(res.data.lfrsection_data[0][0]).map(x => {
          if (x === "lfr_year" || x === "modified_date") {
            return ({
              id: x, Header: x.replace(/_/g, ' '), width: 150, className: 'text-align',
              accessor: row => (new Date(row[x]).toLocaleDateString('en-EN',
                { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })),
            })
          }
          return ({
            Header: x.replace(/_/g, ' '), accessor: x, width: 150, className: 'text-align',
          })
        });

        for (var i = columnsData.length - 1; i >= 0; i--) {
          if (isInList(columnsData[i].Header, ["lfr id", "lfr issue status"])) {
            columnsData.splice(i, 1);
          }
        }

        var actionsColumns = [
          {
            id: 'button',
            Cell: (row) => (
              this.state.modifyActions ?
                <Button className="btn-success" onClick={() => { this.editLFRIssue(row.original) }}><i className="fa fa-edit"></i> Edit</Button> : <div></div>),
            maxWidth: 80,
            className: "center",
            sortable: false,
            filterable: false,
          }];

        if (columnsData.length > 0) {
          columnsData = actionsColumns.concat(columnsData);
        }
        this.setState({ columnsData: columnsData, lfrsectionData: res.data.lfrsection_data[0] })

      })
  }

  changeSelect = (selectedOption) => {
    if (selectedOption == null) {
      var option = { value: 'all', label: 'Show all' };
      this.setState({ selectedlfr: option });
      this.getLFRSections(option);
    } else {
      this.setState({ selectedlfr: selectedOption });
      this.getLFRSections(selectedOption);
    }
    this.getLFRSectionsCount();
  }

  handleHideLFRSectionForm() {
    this.setState({ formLFRIssue: false });
    this.getLFRSections(this.state.selectedlfr);
    this.getLFRSectionsCount();
  }

  render() {
    console.log(this.state);
    return (
      <div className="animated fadeIn" >
        <br />
        {this.state.formLFRIssue &&
          <LFRSectionForm
            lfrIssueInformation={this.state.lfrIssueInformation}
            hideLFRSectionForm={this.handleHideLFRSectionForm}
            username={this.state.username}
          />
        }
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <strong>LFR Issues Section Count {this.state.entity1}</strong>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="10">
                    <ReactTable
                      data={this.state.lfrsectionCountEntity1}
                      columns={this.state.columnsCountEntity1}
                      defaultPageSize={5}
                      showPagination={false}
                      className="-striped -highlight"
                      sortable={false}
                      getTrProps={(state, rowInfo, column) => {
                        let item = rowInfo !== undefined ? rowInfo.original : { Issues: '' }
                        if (item.Issues === 'Total') {
                          return {
                            className: 'issue-section-total'
                          }
                        } else {
                          if (item.Issues === 'EY' || item.Issues === 'UBS') {
                            return {
                              className: 'issue-section-ey-ubs'
                            }
                          } else {
                            return {};
                          }
                        }

                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <strong>LFR Issues Section Count {this.state.entity2}</strong>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="10">
                    <ReactTable
                      data={this.state.lfrsectionCountEntity2}
                      columns={this.state.columnsCountEntity2}
                      defaultPageSize={5}
                      showPagination={false}
                      className="-striped -highlight"
                      sortable={false}
                      getTrProps={(state, rowInfo, column) => {
                        let item = rowInfo !== undefined ? rowInfo.original : { Issues: '' }
                        if (item.Issues === 'Total') {
                          return {
                            className: 'issue-section-total'
                          }
                        } else {
                          if (item.Issues === 'EY' || item.Issues === 'UBS') {
                            return {
                              className: 'issue-section-ey-ubs'
                            }
                          } else {
                            return {};
                          }
                        }

                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Row>
                  <Col xs="12" lg="11">
                    <strong>LFR Sections</strong>
                  </Col>
                  <Col xs="12" lg="1">
                    <Button block color="link" onClick={() => {
                      //var data = this.issuesData.getResolvedState().sortedData.map(x => { return x._original });
                      downloadCSV({
                        data: this.state.lfrsectionData,
                        separator: ';',
                        filename: 'lfrsection_export.csv',
                      });
                    }}>
                      <img src={Excel_icon} height="20" width="20" id="download_icon" alt="" />
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="8">
                    <Select
                      value={this.state.selectedlfr}
                      options={this.state.lfrOptionList}
                      onChange={this.changeSelect}
                      isClearable
                      isSearchable
                      placeholder="Select a LFR Section ..."
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <ReactTable
                      data={this.state.lfrsectionData}
                      columns={this.state.columnsData}
                      defaultPageSize={10}
                      className="-striped -highlight"
                      filterable={true}
                      defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id
                        return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1 : true
                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

}

export default LFRSection;
