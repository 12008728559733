import React from 'react';
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import 'react-table/react-table.css';
import './style.css'
import Select from 'react-select';
// import DatePicker from 'react-datepicker';

function IssueForm(props) {
  const ratingOptions = [
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 0, label: 'tbd'}
  ];
  const statusOptions = [
    { value: 'completed', label: 'Completed' },
    { value: 'agreed', label: 'Agreed but waiting for management response & actions' },
    { value: 'discussion', label: 'Management discussion in progress' },
    { value: 'accuracy check', label: 'Factual accuracy check in progress' },
    { value: 'draft', label: 'EY DRAFT (not shared with client)' },
    { value: 'discarded', label: 'DISCARDED'}
  ]
  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
            <FormGroup row>
              <Col md="2">
                <Label htmlFor="issue_title">Issue Title</Label>
              </Col>
              <Col xs="12" md="10">
                <Input type="text" name="issue_title" value={props.issue_title} onChange={props.handleInputChange}/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="2">
              <Label htmlFor="work_program">Work Program</Label>
              </Col>
              <Col xs="12" md="10">
                <Select
                    value={props.selectedWProg}
                    options={props.wprog_option_list}
                    onChange={props.handleWProgChange}
                    onSelectResetsInput={false}
                    placeholder=""
                  />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="2">
                <Label htmlFor="issue_statement">Issue Statement</Label>
              </Col>
              <Col xs="12" md="10">
                <Input type="textarea" name="issue_statement" value={props.issue_statement} onChange={props.handleInputChange}/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="2">
                <Label htmlFor="issue_due_date">Issue Due Date</Label>
              </Col>
              {/* <Col xs="12" md="10">
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  selected={props.issue_due_date}
                  onChange={props.handleDueDateChange}
                />
              </Col> */}
              <Col xs="12" md="10">
                <Input type="date" name="issue_due_date" value={props.issue_due_date} onChange={props.handleInputChange}/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="2">
                <Label htmlFor="issue_rating">Issue Rating</Label>
              </Col>
              <Col xs="12" md="10">
                <Select
                    value={props.selectedRating}
                    options={ratingOptions}
                    onChange={props.handleRatingChange}
                    onSelectResetsInput={false}
                    placeholder=""
                  />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="2">
                <Label htmlFor="business_division">Owning Legal Entity</Label>
              </Col>
              <Col xs="12" md="10">
                <Input type="text" name="owning_legal_entity" value={props.owning_legal_entity} onChange={props.handleInputChange}/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="2">
                <Label htmlFor="ubs_issue_owner">UBS Issue Owner</Label>
              </Col>
              <Col xs="12" md="10">
                <Input type="text" name="ubs_issue_owner" value={props.ubs_issue_owner} onChange={props.handleInputChange}/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="2">
                <Label htmlFor="ubs_contact_last_discussed_with">UBS Contact Last Discussed With</Label>
              </Col>
              <Col xs="12" md="10">
                <Input type="text" name="ubs_contact_last_discussed_with" value={props.ubs_contact_last_discussed_with} onChange={props.handleInputChange}/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="2">
                <Label htmlFor="issue_status">Issue Status</Label>
              </Col>
              <Col xs="12" md="10">
                <Select
                    value={props.selectedStatus}
                    options={statusOptions}
                    onChange={props.handleStatusChange}
                    onSelectResetsInput={false}
                    placeholder=""
                  />
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

export default IssueForm;