import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Input
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import Excel_icon from '../Icons/download_icon/Excel_icon.png';
import './style.css';
import axios from 'axios';
import { get, post, postAll } from "../../services/http";
import { downloadCSV } from '../CSVExport/CSVExport.js';
import Select from 'react-select';
import _ from 'lodash';

import EYIssueForm from '../EYIssueForm/EYIssueForm';

import { authentication_check } from '../Authentication/Authentication.js';
import token from '../../token.js';

class EYIssue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lfrOptionList: [],
      selectedlfr: { value: 'all', label: 'Show all' },
      entityGroup: 'UBS Group AG',
      entityCH: 'UBS CH AG',
      groupData: [],
      chData: [],
      issuesData: [],
      columnsGroup: [],
      columnsCH: [],
      columnsEyIssues: [],
      formEYIssue: false,
      createEYIssue: true,
      eyIssueInformation: [],
      eyModifiedBy: 'admin',
      comment: '',
      showDeleteConfirmation: false,
      modifyActions: false,
    };

    this.changeSelect = this.changeSelect.bind(this);
    this.getEYIssuesCount = this.getEYIssuesCount.bind(this);
    this.getEYIssues = this.getEYIssues.bind(this);
    this.createNewIssue = this.createNewIssue.bind(this);
    this.editEYIssue = this.editEYIssue.bind(this);
    this.deleteEYIssue = this.deleteEYIssue.bind(this);
    this.showPopUpDeleteEYIssue = this.showPopUpDeleteEYIssue.bind(this);
    this.handleKeyStroke = this.handleKeyStroke.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleHideEYIssue = this.handleHideEYIssue.bind(this);
    this.handleTableUpdate = this.handleTableUpdate.bind(this);
  }

  componentDidMount() {

      get('/api/issue/issue_get_lfr/')
        .then((res) => {
          const lfrOptionList = res.data.lfr_list[0].map(item => {
            return ({
              value: item.section_lfr,
              label: item.LFR_section
            })
          });
          this.setState({ lfrOptionList: lfrOptionList });
        })

      this.getEYIssues(this.state.selectedlfr);
      this.getEYIssuesCount();

    // get('/api/secure/check_token')
    //   .then(res => {
    //     console.log(res.data);
    //     if (res.data.role !== "READ") {
    //       this.setState({ modifyActions: true });
    //     }
    //   })
  }

  getEYIssues(selectedlfr) {
    post('/api/issue/get_eyissue_count_bylfr/', { lfr_section: selectedlfr.value })
    .then((res) => {
      console.log(res.data);
      this.setState({ issuesData: res.data.ey_issues[0] })
    });
  }

  getEYIssuesCount() {
    let requests = [];
    requests.push({
      url: "/api/issue/get_eyissue_count_byentity/",
      data: {wprog_number: this.state.entityGroup }
    },{
      url: "/api/issue/get_eyissue_count_byentity/",
      data: {wprog_number: this.state.entityCH }
    });
    postAll(requests).then((result) => {
      var columnsGroup = [];
      var columnsCH = [];
      columnsGroup = _.keys(result[0].data.issue_count[0][0]).map(x => {
        return ({
          Header: x.replace(/_/g, ' '), accessor: x, className: 'text-align',
        })
      });

      columnsCH = _.keys(result[1].data.issue_count[0][0]).map(x => {
        return ({
          Header: x.replace(/_/g, ' '), accessor: x, className: 'text-align',
        })
      });

      this.setState({ columnsGroup: columnsGroup, columnsCH: columnsCH })
      this.setState({ groupData: result[0].data.issue_count[0], chData: result[1].data.issue_count[0] });
    });
    // axios.all([
    //   axios.post(process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/issue/get_eyissue_count_byentity/', { entity: this.state.entityGroup }),
    //   axios.post(process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/issue/get_eyissue_count_byentity/', { entity: this.state.entityCH }),
    // ]).then(axios.spread((res_entity1, res_entity2) => {

    //   var columnsGroup = [];
    //   var columnsCH = [];

    //   columnsGroup = _.keys(res_entity1.data.issue_count[0][0]).map(x => {
    //     return ({
    //       Header: x.replace(/_/g, ' '), accessor: x, style: { textAlign: 'center' },
    //     })
    //   });

    //   columnsCH = _.keys(res_entity2.data.issue_count[0][0]).map(x => {
    //     return ({
    //       Header: x.replace(/_/g, ' '), accessor: x, style: { textAlign: 'center' },
    //     })
    //   });

    //   this.setState({ columnsGroup: columnsGroup, columnsCH: columnsCH })
    //   this.setState({ groupData: res_entity1.data.issue_count[0], chData: res_entity2.data.issue_count[0] });

    // }))
  }

  changeSelect = (selectedOption) => {
    if (selectedOption == null) {
      var option = { value: 'all', label: 'Show all' };
      this.setState({ selectedlfr: option });
      this.getEYIssues(option);
    } else {
      this.setState({ selectedlfr: selectedOption });
      this.getEYIssues(selectedOption);
    }
    this.getEYIssuesCount();
  }

  createNewIssue() {
    this.setState({ formEYIssue: true, createEYIssue: true });
  }

  editEYIssue(eyissue) {
    if (this.state.modifyActions) {
      this.setState({ formEYIssue: true, createEYIssue: false, eyIssueInformation: eyissue });
    } else {
      alert("Access Denied! You don't have permission to access this action. ")
    }

  }

  showPopUpDeleteEYIssue(eyissue) {
    if (this.state.modifyActions) {
      this.setState({ showDeleteConfirmation: true, eyIssueInformation: eyissue });
    } else {
      alert("Access Denied! You don't have permission to access this action. ")
    }

  }

  handleKeyStroke(event) {
    if (event.target.value !== "") {
      let newState = {};
      newState[event.target.id] = event.target.value;
      this.setState(newState);
    }
  }

  deleteEYIssue() {
    //Call to the API to deleteEYIssue

    var data = {
      eyIssueId: this.state.eyIssueInformation,
      eyModifiedBy: this.state.eyModifiedBy,
      comment: this.state.comment,
    };

    console.log(data);

    post('/api/issue/delete_ey_issue', data)
    .then((res) => {
      alert(res.data.status);
      this.setState({ showDeleteConfirmation: false });
    });
    this.getEYIssues(this.state.selectedlfr);
    this.getEYIssuesCount();

  }

  handleHideEYIssue() {
    this.setState({ formEYIssue: false });
    this.getEYIssues(this.state.selectedlfr);
    this.getEYIssuesCount();
  }

  handleCloseModal() {
    this.setState({ showDeleteConfirmation: false });
  }


  render() {
    console.log(this.state);
    return (
      <div className="animated fadeIn">
        <br />
        <Modal isOpen={this.state.showDeleteConfirmation}>
          <ModalHeader>
            Delete EY Issue
          </ModalHeader>
          <ModalBody>
            <Card className="p-4">
              <Row>
                <p>Please provide an argumentation to delete this EY Issue</p>
              </Row>
              <Row>
                <Input type="text" placeholder="Comment" value={this.state.comment} onChange={this.handleKeyStroke} id="comment" />
              </Row>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Cancel
            </Button>
            <Button color="primary" disabled={this.state.inputInvalid} onClick={this.deleteEYIssue}>
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
        {this.state.formEYIssue &&
          <EYIssueForm
            eyIssueInformation={this.state.eyIssueInformation}
            createNewIssue={this.state.createEYIssue}
            hideEYIssue={this.handleHideEYIssue}
          />
        }

        <Row>
          <Col>
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <strong>EY Issues Count {this.state.entityGroup}</strong>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="10">
                    <ReactTable
                      data={this.state.groupData}
                      noDataText='Loading...'
                      columns={this.state.columnsGroup}
                      defaultPageSize={1}
                      showPagination={false}
                      className="-striped -highlight"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <strong>EY Issues Count {this.state.entityCH}</strong>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="10">
                    <ReactTable
                      data={this.state.chData}
                      noDataText='Loading...'
                      columns={this.state.columnsCH}
                      defaultPageSize={1}
                      showPagination={false}
                      className="-striped -highlight"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Row>
                  <Col xs="12" lg="11">
                    <strong>EY Issues</strong>
                  </Col>
                  <Col xs="12" lg="1">
                    <Button block color="link" onClick={() => {
                      //var data = this.issuesData.getResolvedState().sortedData.map(x => { return x._original });
                      downloadCSV({
                        data: this.state.issuesData,
                        separator: ';',
                        filename: 'eyissues_export.csv',
                      });
                    }}>
                      <img src={Excel_icon} height="20" width="20" id="download_icon" alt="" />
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="8">
                    <Select
                      value={this.state.selectedlfr}
                      options={this.state.lfrOptionList}
                      onChange={this.changeSelect}
                      isClearable
                      isSearchable
                      placeholder="Select a LFR Section ..."
                    />
                  </Col>
                  <Col lg="2">
                  </Col>
                  <Col lg="2">
                    {this.state.modifyActions ? <Button block color="primary" type="button" onClick={this.createNewIssue}>Create new EY Issue</Button>: <div></div>}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <ReactTable
                      data={this.state.issuesData}
                      columns={[
                        {
                          id: 'button',
                          Cell: (row) => (row.original['ey_issue_id'] ?
                          this.state.modifyActions ?
                           <Button className="btn-success" onClick={() => { this.editEYIssue(row.original) }}><i className="fa fa-edit"></i> Edit</Button> : <div></div>: <div></div>),
                          //Cell: ({ value }) => (value ? <button onClick={() => { this.editEYIssue(value) }}>Edit</button> : <div>not available</div>),
                          maxWidth: 80,
                          className: "center",
                          sortable: false,
                          filterable: false,
                        },
                        {
                          id: 'button', accessor: 'ey_issue_id',
                          Cell: ({ value }) => (value ? 
                            this.state.modifyActions ? <Button className="btn-danger" onClick={() => { this.showPopUpDeleteEYIssue(value) }}> Delete</Button> : <div></div>: <div></div>),
                          maxWidth: 80,
                          className: "center",
                          sortable: false,
                          filterable: false,
                        },
                        { Header: 'Issue ID', width: 80, accessor: 'ey_issue_id', className: "center" },
                        {
                          Header: 'LFR Section', accessor: 'ey_issue_lfr_section',
                          Cell: (row) => (
                            row.original['ey_issue_lfr_section'] + ': ' + row.original['section_title_lfr']
                          ),
                          width: 150,
                        },
                        /* { Header: 'LFR Section Title', accessor: 'section_title_lfr' }, */
                        { Header: 'Issue Title', accessor: 'ey_issue_title', },
                        { Header: 'Work Program', accessor: 'ey_issue_wptitle' },
                        { Header: 'Issue Rating', accessor: 'ey_issue_rating', },
                        { Header: 'Issue Statement', accessor: 'ey_issue_statement', },
                        { Header: 'Issue Status with Bank', accessor: 'ey_issue_status_with_bank' },
                        { Header: 'Legal Entity', accessor: 'ey_issue_entity' },
                        { Header: 'EY Issue Contact', accessor: 'ey_contact' },
                        { Header: 'Audit Topic', accessor: 'ey_issue_audit_topic' },
                        
                      ]}
                      defaultPageSize={10}
                      className="-striped -highlight"
                      filterable={true}
                      defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id
                        return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1 : true
                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  handleTableUpdate() {
    console.log("Entering handleTableUpdate");

    /* this.setState(state => {
        let existing_issues = state.issuesData;
        let new_ey_issues = existing_issues.map(
            existing_ey_issue => {
                if (existing_ey_issue.id !== new_ey_item.id) {
                    return existing_ey_issue;
                }
                else {
                    return new_ey_item;
                }
            }
        );
        existing_issues = new_ey_issues;
  
        return {
            issuesData: existing_issues
        }
    }) */
  }
}

export default EYIssue;
