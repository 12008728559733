import React, { Component } from 'react';

import "./style.css"
//import axios from 'axios';
// import token from '../../token.js';
import { get } from "../../services/http";
import WorkProgramSelector from '../WorkProgramSelector/WorkProgramSelector.js';
import AuditStrategy from '../AuditStrategy/AuditStrategy.js';
import DashboardRelated from '../DashboardRelated/DashboardRelated.js';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wprogOptionList: [],
      selectedWProg: null,
    };
  }

  // authentication_check = (callback_fct) => {
  //   axios.get(process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/secure/check_token')
  //        .then( res => {
  //          if(!res.data.role) {
  //            // if the role is not attached to response, redirect user to login page and return 'stop component'
  //            this.props.history.push('/login');
  //            return 'stop component' ;
  //          } else {
  //            // if the role is attached, the component can be shown -> return 'continue component'
  //            return 'continue component' ;
  //          }
  //        } )
  //        .then( res => {
  //          // res contains either 'continue component' if user is loged in or 'stop component' if user is not logged in
  //          if (res === 'continue component') {
  //            // if user is logged in, start callback function which contains the former componentDidMount Code part, see below
  //            callback_fct() ;
  //          }
  //        } )
  // }

  componentDidMount(){
      get('/api/assign/get_workprograms/')
      .then( (res) => {
        const wprogOptionList = res.data.wprog_list[0];
        this.setState({wprogOptionList: wprogOptionList})
      });
  }

  changeSelect = (selectedOption) => {
    console.log(selectedOption);
    console.log(this.state);
    if (this.state.wprogOptionList.indexOf(selectedOption) > -1){
      this.setState({selectedWProg: selectedOption});
    }
  }

  render(){
    const title = 'Document Dashboard';
    const subtitle = 'Audit Work Program';
    if(this.state.selectedWProg){
      return(
        <div className="animated fadeIn">
          <WorkProgramSelector
            title={title}
            subtitle={subtitle}
            selectedWProg={this.state.selectedWProg}
            wprogOptionList={this.state.wprogOptionList}
            changeSelect={this.changeSelect}
          />
          <AuditStrategy
            selectedWProg={this.state.selectedWProg}
          />
          <DashboardRelated
            selectedWProg={this.state.selectedWProg}
          />
        </div>
      )
    } else {
      return(
        <div className="animated fadeIn">
          <WorkProgramSelector
            title={title}
            subtitle={subtitle}
            selectedWProg={this.state.selectedWProg}
            wprogOptionList={this.state.wprogOptionList}
            changeSelect={this.changeSelect}
          />
        </div>
      )
    }
  }
}

export default Dashboard;
