import axios from 'axios';
import history from "../history";
axios.defaults.withCredentials = true;
const baseUrl = process.env.REACT_APP_EXPRESS_PUBLIC_URL;

const get = (url) => {
    return axios.get(`${baseUrl}${url}`)
    .then(function (response) {
        if(response.data.action === "go_to_login"){
            history.push("/#/login");
            window.location.reload();
        } else if(response.data.action === 'go_to_accessdenied') {
            history.push("/access_denied");
            window.location.reload();
        }
        return response;
    })
    .catch(function (error) {
        return error;
    });
};

const post = (url, body, headers = {}) => {
    return axios.post(`${baseUrl}${url}`, body, {...headers})
    .then(response => {
        if(response.data.action === "go_to_login"){
            history.push("/#/login");
            window.location.reload();
        } else if(response.data.action === 'go_to_accessdenied') {
            history.push("/access_denied");
        }
        return response;
    })
    .catch(function (error) {
        return error;
    });
};

const getAll = (requests) => {
    let promises = [];
    for (let i = 0; i < requests.length; i++) {
        promises.push(axios.get(`${baseUrl}${requests[i].url}`));
    }
    return axios.all(promises).then(axios.spread((...res) => {
        if(res[0].data.action  === "go_to_login"){
            history.push("/#/login");
            window.location.reload();
        } else if(res[0].data.action === 'go_to_accessdenied') {
            history.push("/access_denied");
        }
        return {...res};
    }));
};

const postAll = (requests) => {
    let promises = [];
    for (let i = 0; i < requests.length; i++) {
        promises.push(axios.post(`${baseUrl}${requests[i].url}`, requests[i].data));
    }
    return axios.all(promises).then(axios.spread((...res) => {
        if(res[0].data.action  === "go_to_login"){
            history.push("/#/login");
            window.location.reload();
        } else if(res[0].data.action === 'go_to_accessdenied') {
            history.push("/access_denied");
        }
        return res;
    }));
};

export  {
    get,
    getAll,
    post,
    postAll
};
