import React, { Component } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row
} from 'reactstrap';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Loadable from "react-loading-overlay" ;
import ReactTable from "react-table";
import 'react-table/react-table.css' ;
import './style.css'
import axios from 'axios' ;
import token from '../../token.js' ;


class PdfUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadStatus: false,

      file_info : [],

      loading_layer_isActive: false,
      loading_layer_text : 'Updating ...' ,

      error_message : null ,
    } ;
  };



  UploadFile = (ev) => {
    ev.preventDefault();

    // get number of files to be uploaded
    var number_of_files = this.files_to_be_uploaded.files.length ;

    var currentdate = new Date();
    var current_date_string = '_' + currentdate.getFullYear()
                                  + ("0"+(currentdate.getMonth()+1)).slice(-2)
                                  + ("0"+currentdate.getDate()).slice(-2) + '_'
                                  + ("0"+currentdate.getHours()).slice(-2)
                                  + ("0"+currentdate.getMinutes()).slice(-2) ;

    var table_data = []

    var re = new RegExp('[0-9]-[a-zA-Z]-([0-9]{6})') ;
    const data = new FormData() ;

    var number_of_uploaded_files = 0 ;

    // get names of the files to be uploaded
    if( number_of_files > 0){
      for(var x = 0 ; x < number_of_files; x++){
        var old_file_name = this.files_to_be_uploaded.files[x].name ;

        var id_search = old_file_name.match(re) ;
        var file_ID = 'ID not detected' ;
        var file_ID_long = 'ID not detected' ;
        var new_file_name = '(will not be uploaded)' ;

        // only items with valid ID will be uploaded
        if(id_search){
          file_ID = id_search[1] ;
          file_ID_long = id_search[0] ;
          new_file_name = file_ID + current_date_string + '.pdf' ;

          data.append("files", this.files_to_be_uploaded.files[x], new_file_name);
          number_of_uploaded_files = number_of_uploaded_files + 1;
        }
        table_data.push( {old_file_name: old_file_name,
                          file_ID:       file_ID,
                          file_ID_long:  file_ID_long,
                          new_file_name: new_file_name } )

        }
      data.append("file_information", JSON.stringify(table_data)) ;

      data.append("token", token.getToken()) ;
    }

    if(number_of_uploaded_files === 0 | number_of_files === 0){
      this.setState({ loading_layer_isActive: false, error_message:'No Upload! At least one selected pdf must contain a 6-digit ID number in its title!'})
    } else {
      axios.post(process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/secure/admin/policies/upload/', data, {   headers: { 'Content-Type': 'multipart/form-data' }})
           .then( (res) => {
             console.log(res);
             this.setState({uploadStatus: res.data.status,  loading_layer_isActive: false, file_info: res.data.file_info});
           });

       this.setState({ loading_layer_isActive: true,
                       loading_layer_text : 'Analyzing Files ...',
                     error_message: null}) ;
    }


  }

  finalUpload = () => {
    console.log(this.state.file_info);
    axios.post(process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/secure/admin/policies/final_upload/', {file_info: this.state.file_info, token: token.getToken()})
         .then( (res) => {
           console.log(res);
           this.setState({uploadStatus: res.data.status, loading_layer_isActive: false})
          } )

    this.setState({ loading_layer_isActive: true,
                    loading_layer_text : 'Uploading Files ...',
                    file_info: []})
  }

  componentDidMount(){
    axios.get(process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/secure/admin/check_token')
         .then( res => {
           if(res.data.action === 'go_to_login') this.props.history.push('/login') ;
           if(res.data.action === 'go_to_accessdenied') this.props.history.push('/access_denied') ;
         } )
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <strong>Policy Uploader</strong>
              </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      Please select all policies which need to be uploaded!
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <form onSubmit={this.UploadFile}>
                        <div className="form-group">
                          <input className="form-control" ref={(ref) => {this.files_to_be_uploaded = ref;}} type="file" multiple />
                        </div>
                        <button className="btn btn-success" type="true">Analyze</button>
                      </form>
                    </Col>
                  </Row>
                </CardBody>
            </Card>
          </Col>
        </Row>
        {this.state.error_message ? <Row>
          <Col>
            <Card>
              <CardBody>
                <font color="red"><strong>Warning:</strong> {this.state.error_message}</font>
              </CardBody>
            </Card>
          </Col>
        </Row>: <div />}

        <Loadable
          active={this.state.loading_layer_isActive}
          spinner={true}
          text={this.state.loading_layer_text}
          >
        <Row>
          <Col>
            <Card>
              <CardBody>
                <ReactTable
                  data={this.state.file_info}
                  columns={[{Header: 'Original File Name', accessor: 'old_file_name', maxWidth:170},
                            {Header: 'Short ID', accessor: 'file_ID', maxWidth:90},
                            {Header: 'Long ID', accessor: 'file_ID_long', maxWidth:100},
                            {Header: 'Upload File Name', accessor: 'new_file_name', maxWidth:200},
                            {Header: 'ID Check', accessor: 'ID_Check_Status', maxWidth:150},
                            {Header: 'Document Hash', accessor: 'file_hash'},
                            {Header: 'Duplicate Check', accessor: 'Duplicate_Check_Status'}
                          ]}
                  className="-striped -highlight"
                  defaultPageSize={10}
                  SubComponent={row => {
                    //console.log(row.original);
                    return (
                      <div id="assigned-pol-subcomponent">
                        <Row>
                          <Col>
                            <Card>
                              <CardHeader>
                                Check ID on Database
                              </CardHeader>
                              <CardBody>
                                <ReactTable
                                  data={row.original.DB_ID_Info}
                                  columns={[{Header: 'Short ID', accessor: 'policy_nr', maxWidth:90},
                                            {Header: 'Long ID', accessor: 'policy_id', maxWidth:100},
                                            {Header: 'Upload', accessor: 'upload_date'},
                                            {Header: 'Hash', accessor: 'file_hash'}]}
                                  className="-striped -highlight"
                                  defaultPageSize={5} />

                              </CardBody>
                            </Card>
                          </Col>
                          <Col>
                            <Card>
                              <CardHeader>
                                Check Hash on Database
                              </CardHeader>
                              <CardBody>
                                <ReactTable
                                  data={row.original.DB_Hash_Info}
                                  columns={[{Header: 'Short ID', accessor: 'policy_nr', maxWidth:90},
                                            {Header: 'Long ID', accessor: 'policy_id', maxWidth:100},
                                            {Header: 'Upload', accessor: 'upload_date'},
                                            {Header: 'Hash', accessor: 'file_hash'}]}
                                  className="-striped -highlight"
                                  defaultPageSize={5} />
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    );
                  }}
                />
                <button className="btn btn-success" type="true" onClick={this.finalUpload}>Upload Files!</button>
              </CardBody>
            </Card>
          </Col>
        </Row>
        </Loadable>
      </div>
    );
  }
}

export default PdfUpload;
