import React from 'react';
import 'react-table/react-table.css';
import TableFrame from '../TableFrame/TableFrame.js';
import TableFrameServerSide from '../TableFrame/TableFrameServerSide';
import RelevanceExplanation from '../AssignmentProcess/RelevanceExplanation';
import { IssueSubComponent } from '../IssueSubComponent/IssueSubComponent.js';
import { Badge, Input } from 'reactstrap'
import { authentication_check } from '../Authentication/Authentication.js';
import axios from 'axios';
import {get, post } from "../../services/http";
import AssignExpandButton from '../ViewAssign/ColumnRendering/AssignExpandButton'

const query = '/api/explore/get_issues_remote';

const query_post_assignments = '/api/secure/assign/post_several_issue_assignments/'
const query_fetch_selected_assignments = '/api/assign/fetch_selected_issue_assignments/'
const query_fetch_linked_workprograms = '/api/assign/get_linked_workprograms_issue/'
const table_date = 'issue'; 
const title = 'Issue Table';
const defaultPageSize = 10;



class IssueExplorer extends React.Component {

  constructor(props) {
    super(props);
    this.childRef = React.createRef();
    this.state = {

    }
  }


  //standard URL was get_issues instead of get_issues_remote?start=10&&end=25
  componentDidMount() {
    get('/api/assign/get_workprograms/')
    .then((res) => {
      const wprogOptionList = res.data.wprog_list[0];
      this.setState({ wprogOptionList: wprogOptionList })
    });
  }


  prependColumns = [

    {
      accessor: 'is_assigned',
      Header: 'Assigned',
      Filter: ({ filter, onChange }) => {
        return (
          <Input
    
            onKeyPress={event => {
    
    
              if (event.keyCode === 13 || event.which === 13) {
                onChange(event.target.value)
              }
            }}
          />
        )
      },
      Cell: props => {
        //console.log(props.original)
        return (<AssignExpandButton  
          trigger_update = {this.childRef.current} 
          document_code={props.original["Issue ID"]}
          work_program = {props.original.is_assigned} 
          wprogOptionList =  {this.state.wprogOptionList} 
          query_post_assignments={query_post_assignments}
          query_fetch_selected_assignments={query_fetch_selected_assignments}
          query_fetch_linked_workprograms={query_fetch_linked_workprograms}
          />)
        //trigger_update = {this.childRef.current.triggerTableUpdate()}
      }
    },
    {
      Header: 'Relevance',
      id: 'relevance',
      Cell: (row) => (
        <RelevanceExplanation relevance_score={row.original['Relevance']} data={row} />
      ),
      maxWidth: 90
    },
    {
      accessor: 'StatusText',
      Header: 'Status',
      Filter: ({ filter, onChange }) => {
        return (
          <Input

            onKeyPress={event => {


              if (event.keyCode === 13 || event.which === 13) {
                onChange(event.target.value)
              }
            }}
          />
        )
      },
      Cell: ({ value }) => {

        if (value == 'NEW') {
          return (<center> <Badge className='badge badge-success'>NEW</Badge></center>)
        }
        else if (value == 'MODIFIED') {
          return (<center> <Badge className='badge badge-warning'>MODIFIED</Badge></center>)
        }
        else if (value == 'REMOVED') {
          return (<center> <Badge className='badge badge-danger'>REMOVED</Badge></center>)
        }
        else {
          return (<center> <Badge className='badge badge-secondary'>EXISTING</Badge></center>)
        }

      }
    }]

  render() { 
    return (
      <div>
        {/*<TableFrame query={query} title={title} defaultPageSize={defaultPageSize} SubComponent={IssueSubComponent}  /> */}
        <TableFrameServerSide
          ref={this.childRef}
          query={query}
          title={title}
          subtitle = {"(monthly refresh exp.)"}
          defaultPageSize={defaultPageSize}
          SubComponent={IssueSubComponent}
          prependColumns={this.prependColumns}
          versioning={true}
          wprogOptionList={this.state.wprogOptionList} 
          tableDate = {table_date} />

        {/*<TableFrame query={query}  title={title} defaultPageSize={defaultPageSize} SubComponent={IssueSubComponent}  />*/}
      </div>);
  }
}
export default IssueExplorer;