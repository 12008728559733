export default {
  items: [
    {
      title: true,
      name: 'Digital Audit Assistant',
      wrapper: {
        element: '',
        attributes: {}
      },
    },
    {
      name: 'Home',
      url: '/home',
      icon: 'icon-home'
    },

    {
      title: true,
      name: 'VIEW & ASSIGN',
      wrapper: {
        element: '',
        attributes: {}
      },
    },

    {
      name: 'Policy',
      url: '/explore/policy',
      icon: 'icon-folder-alt',
    },

    {
      name: 'Controls',
      url: '/explore/kpci',
      icon: 'icon-folder-alt',
    },
    {
      name: 'Issues',
      url: '/explore/issue',
      icon: 'icon-folder-alt',
    },
    /*{
      title: true,
      name: 'EY ISSUES',
      wrapper: {
        element: '',
        attributes: {}
      },
    },
    {
      name: 'Overview & Create',
      url: '/eyissue/overview',
      icon: 'icon-folder-alt',
    }, */

    {
      title: true,
      name: 'VISUALIZE & REPORT',
      wrapper: {
        element: '',
        attributes: {}
      },
    },

    {
      name: 'Dashboard',
      url: '/assign/dashboard',
      icon: 'icon-screen-desktop',
    },
    /* {
      name: '2 - LFR Issues',
      url: '/issue/count',
      icon: 'icon-chart',

    }, */

    {
      name: 'Network Analysis',
      url: '/explore/network',
      icon: 'icon-shuffle',
    },

    /*{
      title: true,
      name: 'LFR Section',
      wrapper: {
        element: '',
        attributes: {}
      },
    },
    {
      name: 'Production',
      url: '/lfrsection/production',
      icon: 'icon-folder-alt',
    },*/

    {
      title: true,
      name: 'SUPERUSER',
      wrapper: {
        element: '',
        attributes: {}
      },
    },
    {
      name: 'Admin',
      children: [
        {
          name: 'Policy Upload',
          url: '/admin/pdfupload',
        },
        {
          name: 'App Maintenance',
          url: '/admin/maintenance',
        }
      ],
      icon: 'icon-wrench'
    },
    //    {
    //      name: 'Full Template',
    //      url: 'https://coreui.io/demo/React_Demo/#/dashboard',
    //    },
  ]
};
