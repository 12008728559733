import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
// import Loadable from "react-loading-overlay" ;
import ReactTable from "react-table";
import 'react-table/react-table.css' ;
import './style.css'
import axios from 'axios' ;
import token from '../../token.js' ;
import Select from 'react-select' ;
import { get , postAll } from "../../services/http";

class ProcessView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      process_id: null,
      work_program: null,
      process_name: null,
      process_description: null,
      process_owner: null,
      business_division: null,
      linked_policies: [],
      linked_kpci: [],
      linked_issues: []
    } ;
  };

  change_select_process_id = (selectedOption) => {
    postAll([
      {url: '/api/walkthrough/get_process_information/', data: {process_id:selectedOption.value}},
      {url: '/api/walkthrough/get_policy_linked/', data: {process_id:selectedOption.value}},
      {url: '/api/walkthrough/get_kpci_linked/', data: {process_id:selectedOption.value}},
      {url: '/api/walkthrough/get_issue_linked/',data: {process_id:selectedOption.value}}
    ])
    .then((result) => {
      this.setState({
        process_id: selectedOption.value,
        process_name: result[0].data.process_info[0].Process_Name,
        process_description: result[0].data.process_info[0].Process_Description,
        process_owner: result[0].data.process_info[0].Process_Owner,
        business_division: result[0].data.process_info[0].Business_Division,
        wprog_title: result[0].data.process_info[0].WProg_Title,
        linked_policies: result[1].data.linked_policies,
        linked_kpci: result[2].data.linked_kpci,
        linked_issues: result[3].data.linked_issues
      });
    })
  }

  componentDidMount(){
    get('/api/walkthrough/get_definition_processes/')
    .then( res => {
      this.setState({process_option_list: res.data.processes});
    });
  }

  modify = () => {}

  render() {
    return (
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            <strong> Detailed Process View </strong>
          </CardHeader>
          <CardBody>
            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
              <FormGroup row>
                <Col md="2">
                <Label htmlFor="process-id">Process ID</Label>
                </Col>
                <Col xs="12" md="9">
                  <Select
                      value={this.state.process_id}
                      options={this.state.process_option_list}
                      onChange={this.change_select_process_id}
                      onSelectResetsInput={false}
                      placeholder="Select a process ..."
                    />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md="2">
                <Label htmlFor="work-program">Work Program</Label>
                </Col>
                <Col xs="12" md="9">
                  <Input type="text" id="wprog-title" value={this.state.wprog_title}/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md="2">
                  <Label htmlFor="process-name"> Process Name</Label>
                </Col>
                <Col xs="12" md="9">
                  <Input type="text" id="process-name" value={this.state.process_name}/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md="2">
                  <Label htmlFor="process-description"> Process Description</Label>
                </Col>
                <Col xs="12" md="9">
                  <Input type="textarea" id="process-description" value={this.state.process_description}/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md="2">
                  <Label htmlFor="process-owner"> Process Owner</Label>
                </Col>
                <Col xs="12" md="9">
                  <Input type="text" id="process-owner" value={this.state.process_owner} onChange={this.update_process_owner}/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md="2">
                  <Label htmlFor="business-division">Business Division</Label>
                </Col>
                <Col xs="12" md="9">
                  <Input type="text" id="business-division" value={this.state.business_division} onChange={this.update_business_division}/>
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
          <CardFooter>
            {/* <Button type="submit" size="sm" color="primary" onClick={this.define}><i className="fa fa-dot-circle-o"></i> Define Process  </Button> */}
            <Button type="Reset" size="sm" color="danger" onClick={this.modify}><i className="fa fa-ban"></i> Modify this Process </Button>
          </CardFooter>
        </Card>
        <Card>
          <CardHeader>
            <strong> Linked Policies </strong>
          </CardHeader>
          <CardBody>
            <ReactTable
              ref={(r) => {this.pol_option_list = r}}
              data={this.state.pol_option_list}
              columns={[{Header:'ID', accessor: 'Policy_ID', maxWidth:100},
                        {Header:'Title', accessor: 'Title', maxWidth:450},
                        {Header:'Summary', accessor: 'Summary'},
                        {Header:'Applicability', accessor: 'Applicability', maxWidth:110},
                        ]}
              defaultPageSize={5}
              className="-striped -highlight"
              // filterable={true}
              // defaultFilterMethod= {(filter, row, column) => {
              //       const id = filter.pivotId || filter.id
              //       return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase())>-1 : true
              //     }}
              />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <strong> Linked KPCIs </strong>
          </CardHeader>
          <CardBody>
            <ReactTable
              ref={(r) => {this.kpci_option_list = r}}
              data={this.state.kpci_option_list}
              columns={[{Header:'ID', accessor: 'KPCI_ID', maxWidth:100},
                        {Header:'Title', accessor: 'KPCI_Title',maxWidth:500},
                        {Header:'Description', accessor: 'KPCI_Description'}
                      ]}
              defaultPageSize={5}
              className="-striped -highlight"
              // filterable={true}
              // defaultFilterMethod= {(filter, row, column) => {
              //       const id = filter.pivotId || filter.id
              //       return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase())>-1 : true
              //     }}
              />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <strong> Linked Issues </strong>
          </CardHeader>
          <CardBody>
            <ReactTable
              ref={(r) => {this.issue_option_list = r}}
              data={this.state.issue_option_list}
              columns={[{Header:'ID', accessor: 'Issue_ID', maxWidth:100},
                        {Header:'Title', accessor: 'Issue_Title', maxWidth:450},
                        {Header:'Description', accessor: 'Issue_Description'},
                        {Header:'Highest Overall Rating', accessor: 'Issue_Highest_Rating',maxWidth:80,className:'center'}
                      ]}
              defaultPageSize={5}
              className="-striped -highlight"
              // filterable={true}
              // defaultFilterMethod= {(filter, row, column) => {
              //       const id = filter.pivotId || filter.id
              //       return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase())>-1 : true
              //     }}
              />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default ProcessView;
