import React from 'react';
import 'react-table/react-table.css';
import TableFrame from '../TableFrame/TableFrame.js';
import TableFrameServerSide from '../TableFrame/TableFrameServerSide';
import RelevanceExplanation from '../AssignmentProcess/RelevanceExplanation';
import { Badge, Input, Button } from 'reactstrap'
import AssignExpandButton from '../ViewAssign/ColumnRendering/AssignExpandButton'
import { authentication_check } from '../Authentication/Authentication.js';
import axios from 'axios';
import { get } from "../../services/http";
//const query = process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/explore/get_policies/'; //This calls the db POLICY
const query_remote = '/api/explore/get_policies_remote/';

//Queries for assignment view
//temp comment

const query_post_assignments = '/api/secure/assign/post_several_policy_assignments/'
const query_fetch_selected_assignments = '/api/assign/fetch_selected_policy_assignments/'
const query_fetch_linked_workprograms = '/api/assign/get_linked_workprograms/'
const table_date = 'policy'; 
const title = 'Policy Table';
const defaultPageSize = 10;




function openPolicy(id) {
   window.open(process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/secure/get/policy/?id=' + id, '_blank');
}


class PolicyExplorer extends React.Component {
  constructor(props) {
    super(props);
    this.childRef = React.createRef();
    this.state = {

    };

    this.prependColumns = [
      //AssignExpandButton
      {
        accessor: 'is_assigned',
        Header: 'Assigned',
        Filter: ({ filter, onChange }) => {
          return (
            <Input

              onKeyPress={event => {


                if (event.keyCode === 13 || event.which === 13) {
                  console.log("ASSIGN");
                  console.log(event.target.value);
                  onChange(event.target.value)
                }
              }}
            />
          )
        },
        Cell: props => {
          return (<AssignExpandButton
            trigger_update={this.childRef.current}
            document_code={props.original.policy_code}
            work_program={props.original.is_assigned}
            wprogOptionList={this.state.wprogOptionList}
            query_post_assignments={query_post_assignments}
            query_fetch_selected_assignments={query_fetch_selected_assignments}
            query_fetch_linked_workprograms={query_fetch_linked_workprograms}
          />)
          //trigger_update = {this.childRef.current.triggerTableUpdate()}
        }
      },
      {
        Header:'Relevance', 
        id: 'relevance',
        Cell: (row) => (
          <RelevanceExplanation relevance_score={row.original['Relevance']} data = {row}/>
        ),
        maxWidth:90
      },
      {
        accessor: 'StatusText',
        Header: 'Status',
        Filter: ({ filter, onChange }) => {
          return (
            <Input

              onKeyPress={event => {


                if (event.keyCode === 13 || event.which === 13) {
                  onChange(event.target.value)
                }
              }}
            />
          )
        },
        Cell: ({ value }) => {

          if (value == 'NEW') {
            return (<center> <Badge className='badge badge-success'>NEW</Badge></center>)
          }
          else if (value == 'MODIFIED') {
            return (<center> <Badge className='badge badge-warning'>MODIFIED</Badge></center>)
          }
          else if (value == 'WITHDRAWN') {
            return (<center> <Badge className='badge badge-danger'>WITHDRAWN</Badge></center>)

          }
          else {
            return (<center> <Badge className='badge badge-secondary'>EXISTING</Badge></center>)
          }
        }
      },
      {
        accessor: 'policy_code',
        Header: 'Policy Code',
        Filter: ({ filter, onChange }) => {
          return (
            <Input

              onKeyPress={event => {


                if (event.keyCode === 13 || event.which === 13) {
                  onChange(event.target.value)
                }
              }}
            />
          )
        },
        //Code seems to check whether a value is available, but that should always be the case. 
        //Temporarily rewrite this cell in a simpler way and keep the old code for when it breaks
        //Cell: ({ value }) => (value ? <button onClick={() => { openPolicy(value) }}>{value}</button> : <div>not available</div>)
        Cell: ({ value }) => <Button size='sm' block onClick={() => { openPolicy(value) }}> <font size={2}> {value}</font></Button>

      },]


  }


  componentDidMount() {
      get('/api/assign/get_workprograms/')
      .then((res) => {
        if (res.data.wprog_list !== undefined) {
          const wprogOptionList = res.data.wprog_list[0];
          this.setState({ wprogOptionList: wprogOptionList })
        }
      });
  }



  render() {

    return (

      <div>
        <TableFrameServerSide
          ref={this.childRef}
          query={query_remote}
          title={title}
          subtitle={"(monthly refresh exp.)"}
          defaultPageSize={defaultPageSize}
          prependColumns={this.prependColumns}
          versioning={true}
          wprogOptionList={this.state.wprogOptionList} 
          tableDate = {table_date}/>
      </div>
    )
  }


}



export default PolicyExplorer;
