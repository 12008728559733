import React, { Component } from 'react';


import {
    ListGroup,
    ListGroupItem,
    ListGroupItemText,
    ListGroupItemHeading,
    Input

} from 'reactstrap'

import './modal.css';

const status_map = { 'default': 'secondary', 'assigned': 'success', 'unassigned': 'danger' }

class ListViewer extends Component {
    constructor(props) {
        super(props);

        //this.updateComments  = (key, event) => this.props.updateComments(key, event)
        this.state = {
            list: this.props.list
        };

        this.tableEntries = this.tableEntries.bind(this)
        this.UpdateComment = this.UpdateComment.bind(this)


    }

    componentWillReceiveProps(nextProps) {
        console.log('received props')
        console.log(nextProps)
        this.setState({ list: nextProps.list })
    }


    UpdateComment(event, key) {

        this.props.updateComments(key, event.target.value)
    }


    tableEntries(listValue) {
        if (listValue.status == 'default') {
            var disabled = true
            console.warn(listValue.previous_comment)
            if (listValue.previous_comment.length > 0) {

                var placeholder = listValue.previous_comment
            }
            else {
                var placeholder = 'No comment available'
            }
        }
        else {
            var disabled = false
            var placeholder = 'Please enter a comment motivating your assignment request. \nPrevious comment: "' + listValue.previous_comment + '"'
        }
        return <ListGroupItem action color={status_map[listValue.status]}>
            <ListGroupItemHeading><span> Workprogram {listValue.work_program_title} </span><span className="small text-muted float-right lbl-align" > {listValue.date.slice(0, 10)} </span></ListGroupItemHeading>
            <ListGroupItemText>
                <Input
                    required type="textarea"
                    rows="2"
                    disabled={disabled}
                    value={listValue.comment}
                    onChange={event => this.UpdateComment(event, listValue.key)}
                    placeholder={placeholder}
                />
                <span className="small text-muted float-right lbl-align"> Previously assigned by {listValue.author} </span>

            </ListGroupItemText>
        </ListGroupItem>;
    }

    render() {

        return (

            <div className="container">
                <ListGroup>
                    {
                        //map items to list
                        this.state.list.map(listValue => this.tableEntries(listValue))
                        //end map items to list
                    }
                </ListGroup>
            </div>
        )
    }


}

export default ListViewer