import React, { Component } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';


class AccessDenied extends Component {

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3>Access Denied!</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    You do not have the rights to access this page!
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AccessDenied;
