import React from 'react';
import {
  Card,
  CardBody,
  Col,
  Row
} from 'reactstrap';

import './style.css'

function TwoColLine(props) {
  return(
    <div>
      <Row>
        <Col xs={props.split}>
          <strong>{props.name}:</strong>
        </Col>
        <Col>
          {props.field !== "01 Jan 1900" ? props.field : "-"}
        </Col>
      </Row>
    </div>
  );}

export function KPCISubComponent(row){
  return (
    <div>
      <Row>
        <Col xl = "7" lg="5">
          <Card>
            <CardBody>
              <TwoColLine name="KPC Title" field={row.original["Control Instance Title"]} split="1" />
              <TwoColLine name="KPC ID" field={row.original["Control ID"]} split="1" />
              <TwoColLine name="KPCi ID" field={row.original["Control Instance ID"]} split="1" />
              <TwoColLine name="KPCi Owner" field={row.original["Control Instance Owner Name"]} split="1" />
              <TwoColLine name="KPCi Description" field={row.original["Control Instance Description"]} split="1" />
              <TwoColLine name="Local / Functional Information" field={row.original["Local / Functional Information"]} split="1" />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl="3" lg="2">
          <Card>
            <CardBody>
              <TwoColLine name="Execution Frequency" field={row.original["Execution Frequency"]} split="3" />
              <TwoColLine name="Manual/Automation Level" field={row.original["Manual/Automation Level"]} split="3" />
              <TwoColLine name="IT Application" field={row.original["IT Application"]} split="3" />
              <TwoColLine name="Function" field={row.original["GCRS Function Name"]} split="3" />
              <TwoColLine name="Country" field={row.original["GCRS Location - Country"]} split="3" />
              <TwoColLine name="Legal Enity" field={row.original["GCRS Location - Legal Entity"]} split="3" />
            </CardBody>
          </Card>
        </Col>
        <Col xl = "4"lg="3">
          <Card>
          <TwoColLine name="Evidence Description" field={row.original["Evidence Description"]} split="2" />
          <TwoColLine name="Taxonomy" field={row.original["taxonomy_3"]} split="2" />
          </Card>

        </Col>
      </Row>
    </div>
  )
}
window.addEventListener('resize', function() {
  console.log("Width: " + window.innerWidth + ", Height: " + window.innerHeight);
  console.log("Total Pixels: " + window.innerWidth*window.innerHeight);
});
