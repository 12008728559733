import React, { Component } from 'react';
import 'react-table/react-table.css';
import './modal.css'
import Select from 'react-select'
import axios from 'axios';
import chroma from 'chroma-js'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, Card, CardBody, CardHeader, CardGroup, Col, Row, FormGroup } from 'reactstrap';

import 'react-table/react-table.css';
import './style.css'

import { authentication_check } from '../Authentication/Authentication.js';
import { post, getAll } from '../../services/http';

const color_default = '#333333'
const color_new = '#00875A'
const color_removed = '#FF5630'

const colourOptions = [

  { value: 'BA001', label: 'BA001', color: '#333333', isFixed: true, isDisabled: false },
  { value: 'BA002', label: 'BA002', color: '#333333', isFixed: true, isDisabled: false },
  { value: 'BA003', label: 'BA003', color: '#333333', isFixed: true, isDisabled: false },
  { value: 'BA004', label: 'BA004', color: '#00875A', isFixed: false, isDisabled: false },
  { value: 'BA005', label: 'BA005', color: '#00875A', isFixed: false, isDisabled: false },
  { value: 'BA006', label: 'BA006', color: '#00875A', isFixed: false, isDisabled: false },
  { value: 'BA007', label: 'BA007', color: '#00875A', isFixed: false, isDisabled: true },
];

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFixed, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};

const colourStylesUnassigned = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFixed, isFocused, isSelected }) => {
    const color = chroma(color_removed);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? color_removed
          : isFocused
            ? color.alpha(0.1).css()
            : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? color_removed : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(color_removed);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: color_removed,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: color_removed,
    ':hover': {
      backgroundColor: color_removed,
      color: 'white',
    },
  }),
};

function isInList(num, list) {
  // List is an Array()
  var result = false;
  for (var i in list) {
    if (num == list[i]) { result = true }
  }
  return result
}


class LFRSectionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      message: '',
      inputInvalid: false,

      eyLFRid: '',
      eytitle: '',
      eyissueID: '',
      eyLFRRelevant: '',
      eyEntity: '',
      eyLfr: '',
      lfrYear: '',
      lfrStatus: '',
      rating: '',
      eyIssueAssurance: '',
      eyLFRReference: '',
      eyCreatedBy: 'admin',
      eyModifiedBy: 'admin',

      entityList: [<option value="0" key="0" >Select an option..</option>],
      lfrList: [<option value="0" key="0" >Select an option..</option>],

      selectedItems: [], // selected options, needs to be managed
      selectedOptions: [],
      unassignedItems: [],                        //unassigned items
      defaultSelected: [],
      value: null,
      extra_assigned: []

    };

    //this.validForm = this.validForm.bind(this);
    this.handleKeyStroke = this.handleKeyStroke.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.selectHandler = this.selectHandler.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onChangeUnassigned = this.onChangeUnassigned.bind(this)

    this.onAssuranceChanged = this.onAssuranceChanged.bind(this)
    this.onRelevantChanged = this.onRelevantChanged.bind(this)

    this.defaultSelected = [colourOptions[0], colourOptions[1], colourOptions[2]] //Default workprograms
    this.allOptions = colourOptions //All workprograms

  }

  componentDidMount() {
    getAll([
      { url: '/api/issue/issue_get_lfr/'},
      { url: '/api/issue/issue_get_bank_entities/'},
    ]).then((result) => {

      // LRF Sections
      /* const lfrOptionList = res_lfr.data.lfr_list[0].map(item => {
        return (<option value={item.section_lfr} key={item.section_lfr}>{item.LFR_section}</option>)
      });
      const newLFRList = this.state.lfrList.concat(lfrOptionList); */

      //Entities List
      const entitiesOptionList = result[1].data.entities_list[0].map(item => {
        return (<option value={item.bank_entitiy} key={item.bank_entitiy}>{item.bank_entitiy}</option>)
      });
      const newEntityList = this.state.entityList.concat(entitiesOptionList);

      this.setState({
        //lfrList: newLFRList,
        entityList: newEntityList,
      });

      // SECTION LIST+

      var section_id_list = this.props.lfrIssueInformation.section_lfr.substr(0, this.props.lfrIssueInformation.section_lfr.length - 1).split(";");
      var section_title_list = this.props.lfrIssueInformation.section_title.split(";");
      var lfr_assign_list = [];

      for (var i = 0; i < section_id_list.length; i++) {
        var item = {
          value: section_id_list[i],
          label: section_id_list[i] + ": " + section_title_list[i]
        }
        lfr_assign_list.push(item);
      }

      var DefaultSelected = lfr_assign_list
      var Options = result[0].data.lfr_list[0]
      var DefaultAssignedIds = []

      for (var i = 0; i < DefaultSelected.length; i++) {
        DefaultSelected[i]['color'] = color_default
        DefaultSelected[i]['isFixed'] = true
        DefaultSelected[i]['isDisabled'] = false
        DefaultAssignedIds.push(DefaultSelected[i].label)
      }
      //format all 
      //format selection
      for (var i = 0; i < Options.length; i++) {
        Options[i]['label'] = Options[i].LFR_section;
        Options[i]['value'] = Options[i].section_lfr;

        if (isInList(Options[i].label, DefaultAssignedIds)) {
          Options[i]['color'] = color_default
          Options[i]['isFixed'] = true
          Options[i]['isDisabled'] = false
        }
        else {
          Options[i]['color'] = color_new
          Options[i]['isFixed'] = false
          Options[i]['isDisabled'] = false
        }
      }

      this.setState({
        defaultSelected: DefaultSelected,
        selectedItems: DefaultSelected,
        selectedOptions: Options
      })

    });

    this.setState({
      eyLFRid: this.props.lfrIssueInformation.lfr_id,
      eyissueID: this.props.lfrIssueInformation.issue_id,
      eytitle: this.props.lfrIssueInformation.issue_title,
      eyEntity: this.props.lfrIssueInformation.legal_entity,
      eyLFRRelevant: this.props.lfrIssueInformation.lfr_relevance,
      eyIssueAssurance: this.props.lfrIssueInformation.issue_assurance,
      eyLFRReference: this.props.lfrIssueInformation.lfr_issue_reference,
      lfrYear: this.props.lfrIssueInformation.lfr_year,
      lfrStatus: this.props.lfrIssueInformation.lfr_issue_status,
      rating: this.props.lfrIssueInformation.rating,
    });

  }

  handleCloseModal() {
    this.props.hideLFRSectionForm();
  }

  orderOptions = values => {
    if (values) {
      return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));
    }
    else {
      return (values)
    }
  };


  //Stores the selected items in a state, TO BE REMOVED
  selectHandler(e) {
    this.setState({ selectedItems: e })
  }

  //Handles change of the top selection menu. 
  onChange(value, { action, removedValue }) {
    var unassignedItems = this.state.unassignedItems

    switch (action) {
      case 'remove-value':
        var inDefault = isInList(removedValue.label, this.state.defaultSelected.map(x => x.label)) //Check if a default value is removed
        if (inDefault) { //removedValue.isFixed
          unassignedItems.push(removedValue)
        }
        if (value) {
          for (var i = value.length - 1; i >= 0; i--) {
            if (value[i] === removedValue) {
              value.splice(i, 1);
            }
          }
        }
        break;
      case 'pop-value':
        //TODO: Introduce same logic as with remove-value for pop
        console.log('pop')
        break;
      case 'select-option':
        var unassignedTemp = []
        for (var i = 0; i < unassignedItems.length; i++) {
          if (!isInList(unassignedItems[i], value)) {
            unassignedTemp.push(unassignedItems[i])
          }
        }
        unassignedItems = unassignedTemp
        break;
      case 'clear':
        value = this.state.defaultSelected//colourOptions.filter(v => v.isFixed);
        unassignedItems = []
        break;
    }
    value = this.orderOptions(value);
    unassignedItems = this.orderOptions(unassignedItems)
    var extra_assigned = []
    if (value) {
      for (var i = 0; i < value.length; i++) {
        var inDefaultSelect = isInList(value[i], this.state.defaultSelected)
        if (!inDefaultSelect) {
          extra_assigned.push(value[i])
        }
      }
    }
    else {
      extra_assigned = []
    }

    this.setState({
      selectedItems: value,
      unassignedItems: unassignedItems,
      extra_assigned: extra_assigned

    });

  }

  //Handles changes in the bottom selection menu
  onChangeUnassigned(value, { action, removedValue }) {
    var unassignedItems = this.state.unassignedItems
    var reassignedItems = this.state.selectedItems

    switch (action) {
      case 'remove-value':
        for (var i = unassignedItems.length - 1; i >= 0; i--) {
          if (unassignedItems[i].label === removedValue.label) {
            unassignedItems.splice(i, 1);
          }
        }
        if (reassignedItems) {
          reassignedItems.push(removedValue)
        }
        else {
          reassignedItems = [removedValue]
        }
        break;
      case 'pop-value':
        break;
      case 'select-option':
        var reassignedTemp = []
        for (var i = 0; i < reassignedItems.length; i++) {
          if (!isInList(reassignedItems[i], value)) {
            reassignedTemp.push(reassignedItems[i])
          }
        }
        reassignedItems = reassignedTemp
        unassignedItems = value
        break;
      case 'clear':
        //for (var i = 0; i < unassignedItems.length; i++) {
        //    reassignedItems.push(unassignedItems)
        //}
        if (reassignedItems) {
          reassignedItems = reassignedItems.concat(unassignedItems)
        }
        else {
          reassignedItems = unassignedItems
        }
        unassignedItems = [];
        break;
    }

    reassignedItems = this.orderOptions(reassignedItems)
    unassignedItems = this.orderOptions(unassignedItems)

    this.setState({
      selectedItems: reassignedItems,
      unassignedItems: unassignedItems
    });

    var extra_assigned = []

    if (reassignedItems) {
      for (var i = 0; i < reassignedItems.length; i++) {
        var inDefaultSelect = isInList(reassignedItems[i], this.state.defaultSelected)
        if (!inDefaultSelect) {
          extra_assigned.push(reassignedItems[i])
        }

      }

    }

  }

  handleKeyStroke(event) {
    if (event.target.value !== "0") {
      let newState = {};
      newState[event.target.id] = event.target.value;
      this.setState(newState);
      this.setState({
        showAlert: false,
      });
    }
  }

  onAssuranceChanged(event) {
    this.setState({
      eyIssueAssurance: event.currentTarget.value
    });
  }

  onRelevantChanged(event) {
    this.setState({
      eyLFRRelevant: event.currentTarget.value
    });
  }

  handleSubmit() {

    if (this.state.eyLFRReference.trim() === "") {
      this.setState({
        showAlert: true,
        message: 'LFR Issue Reference cannot be empty!',
      });
    } else {
      if (this.state.eyEntity === "") {
        this.setState({
          showAlert: true,
          message: 'Please select a legal entity!',
        });
      } else {
        var lfr_sections = '';

        this.state.selectedItems.map(section => {
          lfr_sections = lfr_sections + ";" + section.value
        })
        //Remove first ;
        lfr_sections = lfr_sections.charAt(0) === ';' ? lfr_sections.substr(1) : lfr_sections;

        var data = {
          lfr_id: this.state.eyLFRid,
          lfr_relevance: this.state.eyLFRRelevant,
          legal_entity: this.state.eyEntity,
          issue_assurance: this.state.eyIssueAssurance,
          lfr_section: lfr_sections,
          rating: this.state.rating,
          lfr_issue_referance: this.state.eyLFRReference,
          issue_id: this.state.eyissueID,
          issue_title: this.state.eytitle,
          lfr_year: this.state.lfrYear,
          lfr_issue_status: this.state.lfrStatus,
          eyCreatedBy: this.props.username,
          eyModifiedBy: this.props.username,
        };

        console.log(data);

        post('/api/lfrsection/update_lfr_issues/', data)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "OK") {
            alert(res.data.message);
            this.handleCloseModal();
          } else {
            alert("Could not update the LFR section. Please verify all the information and try again");
          }
        });
      }
    }
  }

  render() {
    console.log(this.state);
    return (
      <div>
        <Modal size='lg' className='modal-width' isOpen={true} >
          <ModalHeader>
            LFR Section - Details
          </ModalHeader>
          <ModalBody>
            <Row className="justify-content-center">
              <Col md="12">
                <CardGroup>
                  <Card className="p-4">
                    <CardHeader>
                      <Row>
                        <Col>
                          <strong>LFR Section</strong>
                        </Col>
                        <Col md="3">
                          <Button variant="secondary" className='btn-align' onClick={this.handleCloseModal}>
                            Close
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="eyissueID"><strong>Issue ID</strong></Label>
                            <Input disabled type="text" id="eyissueID" value={this.state.eyissueID} onChange={this.handleKeyStroke} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <FormGroup>
                            <Label htmlFor="eytitle"><strong>Issue Title</strong></Label>
                            <Input disabled type="text" id="eytitle" value={this.state.eytitle} onChange={this.handleKeyStroke} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <Label><strong>Assign to LFR Section:</strong></Label>
                          <Row>
                            <Col>
                              <Select
                                value={this.state.selectedItems}
                                closeMenuOnSelect={false}
                                isMulti
                                options={this.state.selectedOptions}
                                styles={colourStyles}
                                onChange={this.onChange}
                              />
                            </Col>
                          </Row>
                          <br />
                          <Label><strong>Unassign to LFR Section:</strong></Label>
                          <Select
                            closeMenuOnSelect={false}
                            value={this.state.unassignedItems}
                            isMulti
                            options={this.state.defaultSelected}
                            styles={colourStylesUnassigned}
                            onChange={this.onChangeUnassigned}
                          />
                          <br />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="title"><strong>Entity</strong></Label>
                            <Input type="select" placeholder="Entity" value={this.state.eyEntity} id="eyEntity" onChange={this.handleKeyStroke}>
                              {this.state.entityList}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <Label htmlFor="title"><strong>LFR Relevant</strong></Label>
                          <FormGroup check className="radio">
                            <Input checked={this.state.eyLFRRelevant === "Yes"} onChange={this.onRelevantChanged} className="form-check-input" type="radio" name="eyLFRRelevant" value="Yes" />
                            <Label check className="form-check-label" htmlFor="radio1">Yes</Label>
                          </FormGroup>
                          <FormGroup check className="radio">
                            <Input checked={this.state.eyLFRRelevant === "No"} onChange={this.onRelevantChanged} className="form-check-input" type="radio" name="eyLFRRelevant" value="No" />
                            <Label check className="form-check-label" htmlFor="radio2">No</Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col>
                                  <strong>To be filled by RECO team</strong>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                <Col xs="6">
                                  <FormGroup>
                                    <Label htmlFor="eyLFRReference">LFR Issue Reference</Label>
                                    <Input type="text" id="eyLFRReference" value={this.state.eyLFRReference} onChange={this.handleKeyStroke} />
                                  </FormGroup>
                                </Col>
                                <Col xs="6">
                                  <Label htmlFor="title">EY Issue Assurance Completed</Label>
                                  <FormGroup check className="radio">
                                    <Input checked={this.state.eyIssueAssurance === "Yes"} onChange={this.onAssuranceChanged} className="form-check-input" type="radio" name="eyIssueAssurance" value="Yes" />
                                    <Label check className="form-check-label" htmlFor="radio1">Yes</Label>
                                  </FormGroup>
                                  <FormGroup check className="radio">
                                    <Input checked={this.state.eyIssueAssurance === "No"} onChange={this.onAssuranceChanged} className="form-check-input" type="radio" name="eyIssueAssurance" value="No" />
                                    <Label check className="form-check-label" htmlFor="radio2">No</Label>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
            <div>
              {this.state.showAlert && <div className='alert alert-danger' role='alert'> {this.state.message} </div>}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Cancel
                  </Button>
            <Button color="primary" disabled={this.state.inputInvalid} onClick={this.handleSubmit}>
              Save Changes
                  </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default LFRSectionForm;
