import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { AppNavbarBrand, AppSidebarToggler} from '@coreui/react';
import {NavItem, NavLink, Nav} from 'reactstrap';
import { get } from "../../services/http";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class FullHeader extends Component {
  constructor(props) {
    super(props);
  }
  render() {

    // eslint-disable-next-line
    const { username, usergroup, children, ...attributes } = this.props;

    const logout = () => {
      get('/api/logout').then(res => {
        if(res.data.status === 200) this.props.history.push('/login');
      });
    };

    return (
      <React.Fragment>

        {/* <AppSidebarToggler className="d-lg-none" display="md" mobile /> */}
        <AppSidebarToggler className="d-lg-none" display="lg" />
        <AppNavbarBrand
          full={{ src: process.env.PUBLIC_URL + '/assets/img/brand/ey_logo.svg', width: 50, height: 50, alt: 'EY Logo' }}
          minimized={{ src: process.env.PUBLIC_URL + '/assets/img/brand/ey_logo.svg', width: 30, height: 30, alt: 'EY Logo' }}
        />

        <Nav navbar>
          <NavItem className="px-3">
            <NavLink href="#" >
              <i className="mr-2"></i>{`${username} (${usergroup})`}
            </NavLink>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="#"  onClick={logout}>
              <i className="fa fa-power-off mr-2"></i>LOG OUT
            </NavLink>
          </NavItem>
        </Nav>

        {/* <Nav className="d-md-down-none" navbar>
          <NavItem className="px-3">
            <NavLink href='#'></NavLink>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="#"></NavLink>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="#"></NavLink>
          </NavItem>
        </Nav> */}

        {/* <AppAsideToggler className="d-md-down-none" /> */}
        {/* <AppAsideToggler className="d-lg-none" mobile /> */}
      </React.Fragment>
    );
  }
}

FullHeader.propTypes = propTypes;
FullHeader.defaultProps = defaultProps;

export default withRouter(FullHeader);
