import React, { Component } from 'react';
import {
  Popover, PopoverBody, PopoverHeader
} from 'reactstrap';


class RelevanceExplanation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
    };

    this.show_relevance_explanation = this.show_relevance_explanation.bind(this)
    this.formatWords = this.formatWords.bind(this)
  }

  show_relevance_explanation() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  formatWords(words) {
    if (words == null) {
      return (<ul></ul>);
    } else {
      var regExp = /\((.*?)\)/g;
      //var matches = words_temp.match(regExp);
      var matches = words.match(regExp);

      var data = [];
      if (matches && matches.length > 0) {
        for (var i = 0; i < matches.length; i++) {
          var str = matches[i].toString();
          str = str.split(",")
          var score = str[1].substring(1, str[1].length - 1)
          var word = str[0].substring(1, str[0].length - 1).substring(1)
          var item = { 'score': score, 'word': word };
          data.push(item);
        }
      }

      const listItems = data.map((item) =>
        <li>{item.word}</li>
      );

      return (
        <ul>{listItems}</ul>
      );
    }
  }

  render() {
    console.log(this.props);
    if (this.props.relevance_score !== null) {
      return (
        <div>
          {parseFloat(this.props.relevance_score).toFixed(3)}
          <a className="card-header-action btn btn-minimize" id={"pop_" + this.props.data.index.toString()} onClick={() => { this.show_relevance_explanation() }}><i className="icon-info" /></a>
          <Popover placement="left" isOpen={this.state.popoverOpen} target={"pop_" + this.props.data.index.toString()} toggle={this.show_relevance_explanation}>

            <PopoverHeader>Explanation of relevance score</PopoverHeader>
            <PopoverBody>
              <p><b>Score</b>: {this.props.relevance_score}</p>

              <b>Most frequent words in <i>Current Document</i></b>

              {this.formatWords(this.props.data.original.words_item)}

              <b>Most frequent words in <i>Work Program</i></b>

              {this.formatWords(this.props.data.original.words_program)}

            </PopoverBody>
          </Popover>
        </div>
      )
    } else {
      return (<div></div>);
    }
  }
}

export default RelevanceExplanation