import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  // CardHeader,
  Col,
  Row,
  Nav, NavItem, NavLink,
  TabContent, TabPane
} from 'reactstrap';

import classnames from 'classnames';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Loadable from "react-loading-overlay" ;
import ReactTable from "react-table";
import 'react-table/react-table.css';
import './style.css';
// import axios from 'axios' ;
import { get, post } from "../../services/http";
import token from '../../token.js' ;

class AppMaintenance extends Component {
  constructor(props){
    super(props);
    this.state = {
      show_restart_button: true,
      file_is_uploaded: false,
      loading_layer_isActive: false,
      error_log: [],
      activeTab: '1',
    };
  }

  componentDidMount(){
    get('/api/secure/admin/check_token').then( res => {
           if(res.data.action === 'go_to_login') this.props.history.push('/login') ;
           if(res.data.action === 'go_to_accessdenied') this.props.history.push('/access_denied');
           this.update_error_log() ;
    });
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  update_error_log = () => {
    get('/api/secure/admin/tools/get_error_log').then( res => {
      this.setState({error_log: res.data.error_log}) ;
    });
  }

  clear_error_log = () => {
    get('/api/secure/admin/tools/clear_error_log')
         .then( res => {
           this.setState({error_log: res.data.error_log}) ;
         })
  }

  UploadModules_Placeholder = () => {}

  UploadModules = (ev) => {
    ev.preventDefault();

    try{
      this.setState({loading_layer_isActive: true}) ;
      // get number of files to be uploaded
      var number_of_files = this.node_modules.files.length ;

      const data = new FormData() ;

      if( number_of_files > 0){
        for(var x = 0 ; x < number_of_files; x++){
          var old_file_name = this.node_modules.files[x].name ;

          data.append("node_modules_parts", this.node_modules.files[x], old_file_name);
        }

        // data.append("token", token.getToken()) ;
      }

      // commented because backend api code also commented due to use of spawn function
      // post('/api/secure/admin/node_modules/upload', data, { headers: { 'Content-Type': 'multipart/form-data' }})
      //      .then( res => {
      //        this.setState({file_is_uploaded: true, loading_layer_isActive: false}) ;
      //      })
      //      .catch( err => {
      //        this.setState({file_is_uploaded: false, loading_layer_isActive: false}) ;
      //      })

    } catch(err) {
      console.log(err);
    }
}


  restart_container = () => {
    // commented because backend api code also commented due to use of spawn function
    // get('/api/secure/admin/tools/container_reload')
    //      .then( res => {
    //        this.setState({has_restarted: true, show_restart_button: false}) ;
    //      })
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h2>App Maintenance</h2>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '1' })}
                onClick={() => { this.toggle('1'); }}
              >
                <strong>Error Log</strong>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '2' })}
                onClick={() => { this.toggle('2'); }}
              >
                <strong>Library Upload</strong>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '3' })}
                onClick={() => { this.toggle('3'); }}
              >
                <strong>App Restart</strong>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Card>
                <CardBody>
                <Row>
                  <Col lg='1'>
                    <Button color="success" onClick={this.update_error_log}>Update</Button>
                  </Col>
                  <Col lg='9' />
                  <Col lg='2'>
                    <Button color="danger" onClick={this.clear_error_log}>Clear Error Log</Button>
                  </Col>
                </Row>
                  <ReactTable
                    data={this.state.error_log}
                    columns={[ {Header: 'Date', accessor: 'Date', maxWidth:90},
                               {Header: 'Time', accessor: 'Time', maxWidth:70},
                               {Header: 'Error', accessor: 'Error',className: 'while-space-hdr'}]}
                    className="-striped -highlight"
                    defaultPageSize={10}
                    filterable={true}
                  />
                </CardBody>
              </Card>
            </TabPane>
            <TabPane tabId="2">
            <Loadable
              active={this.state.loading_layer_isActive}
              spinner={true}
              text={"Uploading files ..."}
              >
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      Since SAMS does not have direct access to open internet, additional Javascript libraries can not be downloaded on-the-fly. <br></br>Instead, in case of additional libraries, all libraries needs to compressed into a tar-file which can then be uploaded here. Due to technical reasons, the tar file must be splitted into several pieces (less than 20MB). These pieces need to be selected and are automatically concatenated afterwards.
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <form onSubmit={this.UploadModules} >
                        <div className="form-group">
                          <input className="form-control" ref={(ref) => {this.node_modules = ref;}} type="file" multiple />
                        </div>
                        <button className="btn btn-success" type="false">Upload</button>
                      </form>
                    </Col>
                  </Row>
                  {this.state.file_is_uploaded ? <Row>
                    <Col>
                      <font color="red">File uploaded!</font>
                    </Col>
                  </Row> : <div />}
                </CardBody>
              </Card>
              </Loadable>
            </TabPane>
            <TabPane tabId="3">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                    The App provided on SAMS can be updated to the latest source code version (commit) on the EY Gitlab. This process has no effect on local App during development<br></br>
                    Note that this process stops the App for roughly 3 min, i.e. there is no service available
                    during this period. If the latest version of the source code is unstable and raises an error, the App will not restart
                    and a manual restart of the App will be required as soon as a stable code is committed to Gitlab.
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {this.state.show_restart_button?<Button color="danger" onClick={this.restart_container}>Update Code and Restart!</Button>:<div />}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {this.state.has_restarted ? <font color="red">The App will be down for the next few minutes. In case the App is not available after 10 min,
                        you have to contact the SAMS system administrator in order to check the log files at "/home/node/app_nfs/data" and to restart the container after
                        the bugs have been solved.</font> : ""}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </TabPane>
          </TabContent>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AppMaintenance;
